module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<li class="filter-timeline__item filter-timeline__item--less">\n	<a href="#" class="filter-timeline__link js-timeline__less">\n		<span class="filter-timeline__text">Свернуть</span>\n	</a>\n</li>\n\n';
 var array = Object.keys(data); ;
__p += '\n\n';
 for(var i=array.length-1; i >= 0 ; i--) { ;
__p += '\n	<li class="filter-timeline__item">\n		<a href="#" class="filter-timeline__link js-timeline__link--years">\n			<span class="filter-timeline__text">' +
((__t = ( array[i] )) == null ? '' : __t) +
'</span>\n		</a>\n		<ul class="filter-timeline__list--2">\n		';

			var arrayMonth = Object.keys(data[array[i]]);
			arrayMonth.sort();
			moment.locale('ru');
		;
__p += '\n			';
 for(var j=arrayMonth.length-1; j >= 0 ; j--) { ;
__p += '\n				<li class="filter-timeline__item filter-timeline__item--years">\n					<a href="#" class="filter-timeline__link filter-timeline__link--years js-timeline__link" data-timestamp-start="' +
((__t = ( data[array[i]][arrayMonth[j]].dateStart )) == null ? '' : __t) +
'" data-timestamp-end="' +
((__t = ( data[array[i]][arrayMonth[j]].dateEnd )) == null ? '' : __t) +
'">\n						' +
((__t = ( moment(data[array[i]][arrayMonth[j]].monthName, 'MM').format('MMMM') )) == null ? '' : __t) +
'\n					</a>\n				</li>\n			';
 } ;
__p += '\n		</ul>\n	</li>\n';
 } ;
__p += '\n';

}
return __p
}