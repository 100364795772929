const $productsNav = $('.js-products__nav');

if ($productsNav.length) {
	$productsNav.on('click', 'a', function(event) {
		event.preventDefault();
		let $this = $(this);
		if ($this.hasClass('is-down')) {
			$productsNav
				.find('a.is-up')
				.removeClass('is-up')
				.addClass('is-down')
				.next('ul')
				.hide();
			$this
				.removeClass('is-down')
				.addClass('is-up')
				.next('ul')
				.show();
			$this
				.next('ul')
				.find('a')
				.eq(0)
				.trigger('click');
		} else if ($this.hasClass('is-up')) {
			return false;
			// $this
			// 	.removeClass('is-top')
			// 	.addClass('is-down')
			// 	.next('ul')
			// 	.velocity('slideUp', {
			// 		duration: 600,
			// 		easing: 'easeOutQuart'
			// 	});
		} else {
			showProductsInfo($this.attr('href').substr(1));
		}
	});
}

function toggleProductsNav() {
	if ($productsNav.length) {
		if ($('[data-products-nav-container]').hasClass('is-expand')) {
			$productsNav
				.closest('.products__aside')
				.show();
		} else {
			$productsNav
				.closest('.products__aside')
				.hide();
		}
	}
}

function showProductsInfo(id) {
	window.location.hash = id;
	let $container = $('[data-id=' + id + ']'),
		$link = $('a[href="#' + id + '"]');
		$productsNav
			.find('a.is-active')
			.removeClass('is-active');
		$('.products-section.is-active')
			.find('.products-section__item.is-active')
			.hide();
		$('.products-section.is-active')
			.removeClass('is-active')
			.hide();

		$container
			.addClass('is-active')
			.show();
		$container
			.closest('.products-section')
			.addClass('is-active')
			.show();
		$link
			.addClass('is-active');
}

if ($productsNav.length) {
	let hash = getHash();
	toggleProductsNav();
	if (hash.type === 'products') {
		let $elem = $('.js-products__nav').find('a[href="' + hash.href + '"]');
		$elem
			.trigger('click')
			.closest('ul')
			.show()
			.siblings('.is-down')
			.removeClass('is-down')
			.addClass('is-up');
	} else if (!($productsNav.find('a.is-up').length)) {
		$productsNav
			.find('a.is-down')
			.eq(0)
			.trigger('click');
	}
	$('.js-products-preloader').velocity('fadeOut', {
		complete: function () {
			$('.js-products-preloader').remove();
		}
	})

	$(document).on('click', '.aside__lang', function(e) {
		e.preventDefault();
		window.location.href = $(this).attr('href') + getHash().href;
	});
}

window.onload = function () {
	$('.js-products-preloader').velocity('fadeOut', {
		complete: function () {
			$('.js-products-preloader').remove();
		}
	})
};

$(window).on('hashchange', function () {
	toggleProductsNav();
});