let $listStepsItem = $('.js-list-steps');

function toggleListSteps(e) {
	e.preventDefault();
	let $thisItem = $(this),
		$activeItem = $thisItem.siblings('.list-steps__item--active');

	if ($thisItem.hasClass('list-steps__item--active')) {
		return false;
	} else {
		$activeItem
			.removeClass('list-steps__item--active')
			.find('.list-steps__content')
			.velocity('slideUp');
		$thisItem
			.addClass('list-steps__item--active')
			.find('.list-steps__content')
			.velocity('slideDown');
	}
}

$(document).on('click', '.js-list-steps', toggleListSteps);

var contentSections = $('section.bm__row'),
	navigationItems = $('#bm-pagination a');

if ($('.bm').length) {
	updateNavigation();
}

$(window).on('scroll', function () {
	if ($('.bm').length) {
		updateNavigation();
	}
});

function updateNavigation() {
	contentSections.each(function (i, el) {
		let $this = $(el);
		var activeSection = $('#bm-pagination a[href="#' + $this.attr('data-id') + '"]').data('number') - 1;
		if (($this.offset().top - $(window).height() / 2 < $(window).scrollTop()) && ($this.offset().top + $this.height() - $(window).height() / 2 > $(window).scrollTop())) {
			navigationItems.eq(activeSection).addClass('is-selected');
		} else {
			navigationItems.eq(activeSection).removeClass('is-selected');
		}
	});
}

function smoothScroll(target) {
	$('body,html').animate(
		{scrollTop: target.offset().top},
		600
	);
	window.location.hash = '!';
}


function checkBMHash() {
	if (window.location.hash.length > 0 && !(window.location.hash == '#!')) {
		var hash = window.location.hash.substr(1);
		if ($('[data-id=' + hash + ']').length > 0) {
			smoothScroll($(`[data-id="${hash}"]`));
		}
	}
}

if ($('.bm').length) {
	checkBMHash();
}

$(window).on('hashchange', function() {
	if ($('.bm').length) {
		checkBMHash();
	}
});
