<template>
    <div v-if="filterData.length" class="media-filter">
        <button
            @click="opened = true"
            :class="{'filter__btn-filter--active': opened}"
            class="filter__btn-filter"
        >{{ text.title }}</button>
        <div
            :class="{'filter--open': opened}"
            class="filter"
        >
            <span class="filter__title">{{ text.title }}</span>
            <button
                @click="opened = false"
                class="filter__close"
            ></button>
            <div class="filter__group">
                <div class="filter__group-heading">
                    <span
                        v-html="type == 'themes' ? text.byTheme : text.byType"
                        class="filter__item"
                    ></span>
                    <button
                        @click.prevent="selectAll()"
                        class="filter-item__filter-link filter__all"
                        href="#"
                    >
                        <span v-if="allSelected">{{ text.clear }}</span>
                        <span v-else>{{ text.all }}</span>
                    </button>
                </div>

                <div class="filter__container">
                    <ul class="filter-item__filter-list ul--clear ul--inline">
                        <li
                            v-for="(item, index) in filterData"
                            class="filter-item__filter-item"
                        >
                            <a
                                @click.prevent="updateUsed(index)"
                                :data-filter-id="item.id"
                                :class="{
                                    'disabled': item.disabled,
                                    'filter-item__filter-link--active': item.selected
                                }"
                                class="filter-item__filter-link"
                                href="#"
                            >{{ item.text }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { debounce } from 'throttle-debounce';

export default {
    name: 'media-filter',
    props: {
        type: {
            type: String,
            default: 'themes'
        },
    	filters: Object
    },
    data() {
        return {
            opened: false,
            lastUsedFilter: '',
            allSelected: false,
            isFilterSelected: false,
            text: {
                all: this.$parent.lang == 'ru' ? 'Все' : 'All',
                title: this.$parent.lang == 'ru' ? 'Фильтр' : 'Filter',
                clear: this.$parent.lang == 'ru' ? 'Очистить' : 'Clear',
                byType: this.$parent.lang == 'ru' ? 'По типу' : 'By type',
                byTheme: this.$parent.lang == 'ru' ? 'По теме' : 'By theme',
            }
        }
    },
    mounted() {
        document.addEventListener('click', e => this.hideFilter(e));
    },
    updated() {
        this.$parent.isFilterSelected = this.checkIsSelected();
    },
    beforeDestroy() {
        document.removeEventListener('click', e => this.hideFilter(e));
    },
    computed: {
        filterData() {
            return this.filters[this.type];
        },
        passParams() {
            let passParams = {
                    page: 1,
                    [this.type]: [],
                    year: this.$parent.params.year,
                    lastUsedFilter: this.lastUsedFilter
                },
                filter = [],
                filtered = this.filterData.filter(item => item.selected ? item : false);

            for (let i = 0; i < filtered.length; i++) {
                filter.push(filtered[i].id);
            };

            passParams[this.type] = filter.toString();

            return passParams;
        },
        debouncedEmitChange() {
            return debounce(500, () => {
                this.checkIsSelected();
                this.$parent.params = this.passParams;
                this.$parent.$emit('changeFilters');
            });
        }
    },
    methods: {
        hideFilter(e) {
            let isOutside = this.$el !== e.target && !this.$el.contains(e.target);
            isOutside ? this.opened = false : false;
        },
        selectAll() {
            this.allSelected = !this.allSelected;

            for (let i = 0; i < this.filterData.length; i++) {
                this.filterData[i].selected = this.allSelected;
            };

            this.debouncedEmitChange();
        },
        checkIsSelected() {
            let status = this.filterData.filter(item => item.selected).length > 0;
            this.isFilterSelected = status;
            return status;
        },
        updateUsed(index) {
            this.filterData[index].selected = !this.filterData[index].selected;
            this.lastUsedFilter = this.type;
            this.debouncedEmitChange();
            this.checkAllSelected();
        },
        checkAllSelected() {
            if (this.filterData.length == this.filterData.filter(item => item.selected).length) {
                this.allSelected = true;
            } else {
                this.allSelected = false;
            };
        },
    }
};
</script>