// var tablesaw = require('tablesaw');

//
// init
//

$(document).ready(function () {
	(function (win) {
		// DOM-ready auto-init of plugins.
		// Many plugins bind to an "enhance" event to init themselves on dom ready, or when new markup is inserted into the DOM
		$(function () {
			$(document).trigger('enhance.tablesaw');
		});

	}(typeof window !== 'undefined' ? window : this));

	$('.js-table-responsive').each(function (index, el) {
		var $this = $(this),
			typeTable = $this.attr('data-table-type'),
			$thisTable = $this.find('table');

		$thisTable
			.attr('data-tablesaw-mode', typeTable)
			.trigger('enhance.tablesaw');
	});
});
