module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


	var LANG = getLang(),
		filterTitle = (LANG == 'ru') ? 'Фильтр' : 'Filter',
		sourceTitle = (LANG == 'ru') ? 'По типу' : 'By type',
		subjectTitle = (LANG == 'ru') ? 'По теме' : 'By topic',
		allText = (LANG == 'ru') ? 'Все' : 'All';
;
__p += '\n\n<span class="filter__title">' +
((__t = ( filterTitle )) == null ? '' : __t) +
'</span>\n<button class="filter__close js-filter__close"></button>\n\n';
 if (data.filters.source.show == true) { ;
__p += '\n	<div class="filter__group">\n';
 } else { ;
__p += '\n	<div class="filter__group" style="display: none;">\n';
 } ;
__p += '\n		<div class="filter__group-heading">\n			<span class="filter__item" data-filter-type="source">' +
((__t = ( sourceTitle )) == null ? '' : __t) +
'</span>\n			<a href="#" data-filter-id="all" class="js-filter__link filter-item__filter-link js-filter__all">' +
((__t = ( allText )) == null ? '' : __t) +
'</a>\n		</div>\n		<div class="filter__container" data-filter-container="source">\n			<ul class="filter-item__filter-list ul--clear ul--inline">\n				';
 for(var i=0; i < data.filters.source.elements.length; i++) { ;
__p += '\n					<li class="filter-item__filter-item">\n						<a href="#" data-filter-id="' +
((__t = ( data.filters.source.elements[i].id )) == null ? '' : __t) +
'" class="js-filter__link filter-item__filter-link ' +
((__t = ( data.filters.source.elements[i].state ? 'filter-item__filter-link--active' : '' )) == null ? '' : __t) +
'">' +
((__t = ( data.filters.source.elements[i].text )) == null ? '' : __t) +
'</a>\n					</li>\n				';
 } ;
__p += '\n			</ul>\n		</div>\n	</div>\n\n';
 if (data.filters.subject.show == true) { ;
__p += '\n	<div class="filter__group">\n';
 } else { ;
__p += '\n	<div class="filter__group" style="display: none;">\n';
 } ;
__p += '\n		<div class="filter__group-heading">\n			<span class="filter__item" data-filter-type="subject">' +
((__t = ( subjectTitle )) == null ? '' : __t) +
'</span>\n			<a href="#" data-filter-id="all" class="js-filter__link filter-item__filter-link js-filter__all">' +
((__t = ( allText )) == null ? '' : __t) +
'</a>\n		</div>\n		<div class="filter__container" data-filter-container="subject">\n			<ul class="filter-item__filter-list ul--clear ul--inline">\n				';
 for(var i=1; i < data.filters.subject.elements.length; i++) { ;
__p += '\n					<li class="filter-item__filter-item">\n						<a href="#" data-filter-id="' +
((__t = ( data.filters.subject.elements[i].id )) == null ? '' : __t) +
'" class="js-filter__link filter-item__filter-link ' +
((__t = ( data.filters.subject.elements[i].state ? 'filter-item__filter-link--active' : '' )) == null ? '' : __t) +
'">' +
((__t = ( data.filters.subject.elements[i].text )) == null ? '' : __t) +
'</a>\n					</li>\n				';
 } ;
__p += '\n					<li class="filter-item__filter-item">\n						<a href="#" data-filter-id="' +
((__t = ( data.filters.subject.elements[0].id )) == null ? '' : __t) +
'" class="js-filter__link filter-item__filter-link ' +
((__t = ( data.filters.subject.elements[0].state ? 'filter-item__filter-link--active' : '' )) == null ? '' : __t) +
'">' +
((__t = ( data.filters.subject.elements[0].text )) == null ? '' : __t) +
'</a>\n					</li>\n			</ul>\n		</div>\n	</div>\n';

}
return __p
}