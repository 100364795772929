const throttle = require('throttle-debounce/throttle');
let $filter = $('.js-news-filter');

// function toggleFilter(e) {
// 	e.preventDefault();
// 	let $thisFilter = $(this),
// 			thisTypeFilter = $thisFilter.data('filter-type'),
// 			$thisFilterContainer = $('.news-filter__container[data-filter-container="'+thisTypeFilter+'"]'),
// 			$openedFilterBtn = $('.news-filter__item.news-filter__item--active'),
// 			$openedFilterContainer = $('.news-filter__container.news-filter__container--active');
//
// 	function closeFilter(){
// 		$openedFilterBtn
// 			.removeClass('news-filter__item--active');
// 		$openedFilterContainer
// 			.hide()
// 			.removeClass('news-filter__container--active');
// 	}
//
// 	if ($thisFilter.hasClass('news-filter__item--active')) {
// 		closeFilter();
// 	} else {
// 		closeFilter();
// 		$thisFilter.addClass('news-filter__item--active');
// 		$thisFilterContainer
// 			.show()
// 			.addClass('news-filter__container--active');
// 	}
// }
//
// function closeFilter() {
// 	$filter.removeClass('news-filter--open');
// 	$('.news__btn-filter').removeClass('news__btn-filter--active');
// }
//
// function updateFilterWidth() {
// 	if ($filter.hasClass('news-filter--open')) {
// 		var widthItem = $('#news-items')
// 											.find('.news-item')
// 											.eq(0)
// 											.outerWidth();
// 		widthItem = widthItem * 2 + 16 * 2; // 13 = base margin gutter - 3
// 		$filter.outerWidth(widthItem);
// 	}
// }
//
// function toggleFilter(e) {
// 	e.preventDefault();
// 	if (!$filter.hasClass('news-filter--open')) {
// 		var widthItem = $('#news-items')
// 											.find('.news-item')
// 											.eq(0)
// 											.outerWidth();
// 		widthItem = widthItem * 2 + 16 * 2; // 13 = base margin gutter - 3
// 		$filter.outerWidth(widthItem);
// 	}
//
// 	$filter.toggleClass('news-filter--open');
// 	$(this).toggleClass('news__btn-filter--active');
//
// }
//
// $(document).on('click', '.news__btn-filter', toggleFilter);
// $(document).on('click', '.js-news-filter__close', closeFilter);
// $(window).resize(
// 	throttle(300, function () {
// 		updateFilterWidth();
// 	})
// );
