var $news = $('#news');
var $timelineYears = $news.find('#filter-timeline--years'),
	$preloader = $(`<div class="col-default-12 news__preloader"><img src="/images/svg/loader.svg"></div>`);
var templateTimeline = require('./news-timeline.ejs');

global.createTimelineYears = function (params) {
	$timelineYears
		// .mCustomScrollbar({
		// 	scrollInertia: 500
		// });

	loadNewsFirst()
};

if ($('#timeline-data-filter').length) {
	createTimelineYears(`AJAX_CALL=Y&${$('#timeline-data-filter').data('timeline-filter')}`);
} else {
	createTimelineYears('AJAX_CALL=Y');
}
