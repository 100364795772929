function initGallery(method = 'init') {
	let $arrGalleries = $('.js-media').find($('[id^="js-media-gallery"]'));

	if (method == 'init') {
		$.each($arrGalleries, function (index, value) {
			var $lg = $(this);

			$lg.lightGallery({
				slideEndAnimatoin: false,
				hideControlOnEnd: true,
				selector: '.js-media__zoom',
				loop: false,
				counter: false,
				hash: false,
				videojs: true,
				galleryId: 1,
				thumbnail: true,
				fullScreen: false,
				// thumbWidth: 130,
				thumbContHeight: 120,
				thumbMargin: 20,
				exThumbImage: 'data-thumb',
				animateThumb: true,
				toogleThumb: false,
				currentPagerPosition: 'middle',
				share: true,
				facebook: false,
				twitter: false,
				googlePlus: false,
				pinterest: false
			});

			$lg.on('onAfterAppendSubHtml.lg', function (event, index) {
				let $el = $(event.currentTarget).find('.js-media__zoom').eq(index),
					subHtml = $el.data('sub-html'),
					ogTitle = $el.data('og-title'),
					ogUrl = $el.data('og-url');
				// console.log($(event.currentTarget));

				$('.lg-toolbar')
					.find('#lg-link')
					.remove();
				$('.lg-toolbar')
					.find('#lg-download')
					.remove();
				$('.lg-inner')
					.find('#lg-download')
					.remove();
				$('.lg-current').removeClass('lg-current--hoverable');

				let $linkBtn = $(subHtml).find('#lg-link');
				if ($linkBtn.length) {
					$linkBtn
						.insertBefore($('.lg-toolbar').find('#lg-share'));
				}

				let $toolbarDownloadBtn = $(subHtml).find('#lg-download');
				if ($toolbarDownloadBtn.length) {
					$toolbarDownloadBtn
						.insertBefore($('.lg-toolbar').find('#lg-share'));
				}

				let $downloadBtn = $('.lg-toolbar').find('#lg-download').clone(),
					titleDownload = localize({
						ru: 'Скачать',
						en: 'Download'
					});
				if ($downloadBtn.length) {
					$downloadBtn.text(titleDownload);
					$downloadBtn
						.appendTo($('.lg-inner').find('.lg-current'));
					$('.lg-inner')
						.find('.lg-current')
						.addClass('lg-current--hoverable');
				}

				if (typeof subHtml !== 'undefined' && subHtml !== null) {
					$('.lg-inner').removeClass('lg-inner--emptyhtml');
				} else {
					$('.lg-inner').addClass('lg-inner--emptyhtml');
				}

				// SHARE

				function loadShareUl() {
					let $shareBtn = $('.lg-toolbar').find('#lg-share'),
						$ul = $shareBtn.find('ul');
					$ul.html(`<li><img src="/images/svg/loader.svg"></li>`);
					$.get('/ajax/social-share.php', {
						ogTitle: ogTitle,
						ogURL: ogUrl
					}, function (response) {
						$ul.html($(response).html());
					});
				}
				loadShareUl();

				// $('.lg-toolbar')
				// 	.find('#lg-share')
				// 	// .attr({
				// 	// 	'data-og-title': ogTitle,
				// 	// 	'data-og-url': ogUrl
				// 	// })
				// 	.on('click', function(e) {
				// 		e.preventDefault();
				// 		let $ul = $(this).find('ul');
				// 		$ul.html(`<li><img src="/images/svg/loader.svg"></li>`);
				// 		console.log('ajax: ' + ogTitle +  ' - ' + ogUrl);
				// 		$.get('/ajax/social-share.php', {
				// 			ogTitle: ogTitle,
				// 			ogURL: ogUrl
				// 		}, function (response) {
				// 			$ul.html($(response).html());
				// 		});
				// 	});
			});
		});
	} else if (method == 'destroy') {
		$.each($arrGalleries, function (index, value) {
			var $lg = $(this);
			if ($lg.data().hasOwnProperty('lightGallery')) {
				$lg.data('lightGallery').destroy(true);
			}
		});
	} else if (method == 'update') {
		initGallery('destroy');
		initGallery();
	}

	return $arrGalleries;
}

// window.lightGallery = initGallery();

export {initGallery};
