const $arrSocial = $('.social-network');
const templateVK = require('./templates/vk.ejs');
const templateFB = require('./templates/fb.ejs');
const templateTW = require('./templates/tw.ejs');
const templateIG = require('./templates/ig.ejs');
const templateYT = require('./templates/yt.ejs');

const $SOCIAL = $('#social-networks'),
	$FB = $SOCIAL.find('.social-network--fb'),
	$VK = $SOCIAL.find('.social-network--vk'),
	$TW = $SOCIAL.find('.social-network--tw'),
	$IG = $SOCIAL.find('.social-network--ig'),
	$YT = $SOCIAL.find('.social-network--yt');
// arSocialServicesData

function getParamsStr(params) {
	return `OWNER_ID=${encodeURIComponent(params.owner)}&SOCIAL_TYPE=${encodeURIComponent(params.social_type)}&AJAX_CALL=Y&PAGEN_1=${encodeURIComponent(params.pagen_1)}&pageSize=${encodeURIComponent(params.page_size)}&currentPage=${encodeURIComponent(params.current_page)}&nextPage=${encodeURIComponent(params.next_page)}&pagingToken=${encodeURIComponent(params.paging_token)}`;
}

function loadSocialJson(ajax = true, singleJson, $obj, ejsTemplateName) {
	if (!ajax) {
		let $objContainer = $obj.find('.social-network__container');
		$objContainer
			.append(ejsTemplateName(singleJson))
			.mCustomScrollbar({
				scrollInertia: 500,
				callbacks: {
					onTotalScrollOffset: 100,
					onTotalScroll: function () {
						if (!$obj.hasClass('social-network--loading')) {
							let $preloaderAjax = $(`<div class="soc__preloader soc__preloader--mini"><img src="/images/svg/loader.svg"></div>`);

							$obj.addClass('social-network--loading');
							$objContainer
								.find('.mCSB_container')
								.append($preloaderAjax);
							$objContainer.mCustomScrollbar('scrollTo', 'bottom');
							// removeParams([PAGEN_1', 'AJAX_CALL']);
							let params = {};
							params.owner = $obj.attr('owner');
							params.social_type = $obj.attr('data-soc-id');
							params.pagen_1 = $obj.attr('next_page');
							params.page_size = $obj.attr('page_size');
							params.current_page = $obj.attr('current_page');
							params.next_page = $obj.attr('next_page');
							params.paging_token = $obj.attr('paging_token');

							let paramsStr = getParamsStr(params);
							// console.log(paramsStr);
							getJson(`/news-and-media/social-networks/?${paramsStr}`)
								.then(function (data) {

									let json = JSON.parse(JSON.stringify(eval('(' + data + ')'))),
										singleJson;

									// console.log(`Пришел ответ:`);
									// console.log(json);

									switch ($obj.data('soc-id')) {
										case 'fb':
											singleJson = json.FB[`${$obj.attr('owner')}`];
											break;
										case 'vk':
											singleJson = json.VK[`${$obj.attr('owner')}`];
											break;
										case 'ig':
											singleJson = json.IG[`${$obj.attr('owner')}`];
											break;
										case 'yt':
											singleJson = json.YT[`${$obj.attr('owner')}`];
											break;
										case 'tw':
											singleJson = json.TW[`${$obj.attr('owner')}`];
											break;
										default:
									}

									loadSocialJson(true, singleJson, $obj, ejsTemplateName);
									return singleJson;
								})
								.catch(function (error) {
									console.error('Error: ' + error);
									showNotyfications(error, {type: 'error'});
								});
						}
					}
				}
			})
			.find('.soc__preloader')
			.velocity('fadeOut', {
				complete: function () {
					$(this).remove();
				}
			});
	} else {
		let $objContainer = $obj.find('.social-network__container');
		$objContainer
			.find('.mCSB_container')
			.append(ejsTemplateName(singleJson))
			.find('.soc__preloader')
			.velocity('fadeOut', {
				complete: function () {
					$(this).remove();
					$obj.removeClass('social-network--loading');
				}
			});
	}

	if ($obj.data('soc-id') == 'yt') {
		singleJson = singleJson;
	}

	if (singleJson.pagination) {
		$obj.attr({
			owner: singleJson.pagination.OWNER_ID,
			pagen_1: singleJson.pagination.PAGEN_1,
			page_size: singleJson.pagination.pageSize,
			current_page: singleJson.pagination.currentPage,
			next_page: singleJson.pagination.nextPage
		});
	} else if (singleJson[Object.keys(singleJson)[0]].pagination) {
		$obj.attr({
			owner: singleJson[Object.keys(singleJson)[0]].pagination.OWNER_ID,
			pagen_1: singleJson[Object.keys(singleJson)[0]].pagination.PAGEN_1,
			page_size: singleJson[Object.keys(singleJson)[0]].pagination.pageSize,
			current_page: singleJson[Object.keys(singleJson)[0]].pagination.currentPage,
			next_page: singleJson[Object.keys(singleJson)[0]].pagination.nextPage
		});
	}

	if ($obj.data('soc-id') == 'fb') {
		$obj.attr({
			paging_token: singleJson.pagination.pagingToken
		});
	} else {
		$obj.attr({
			paging_token: ''
		});
	}
}

function createSocialNetworks() {
	let json = arSocialServicesData;
	console.log(json);
	$arrSocial.each(function (i, el) {
		switch ($(el).data('soc-id')) {
			case 'fb':
				loadSocialJson(false, json.FB[`${$(el).attr('owner')}`], $FB, templateFB);
				break;
			case 'vk':
				loadSocialJson(false, json.VK[`${$(el).attr('owner')}`], $VK, templateVK);
				break;
			case 'ig':
				if (json.IG[`${$(el).attr('owner')}`].hasOwnProperty('ERRORS')) {
					console.error(`Ошибка IG в аккаунте ${$(el).attr('owner')}: ${json.IG[$(el).attr('owner')]['ERRORS'][0]['MSG']}`);
					showNotyfications(`Ошибка IG в аккаунте ${$(el).attr('owner')}: ${json.IG[$(el).attr('owner')]['ERRORS'][0]['MSG']}`, {type: 'error'});
				} else {
					loadSocialJson(false, json.IG[`${$(el).attr('owner')}`], $IG, templateIG);
				}
				break;
			case 'yt':
				loadSocialJson(false, json.YT[`${$(el).attr('owner')}`], $YT, templateYT);
				break;
			case 'tw':
				if (json.TW[`${$(el).attr('owner')}`].hasOwnProperty('ERRORS')) {
					console.error(`Ошибка TW в аккаунте ${$(el).attr('owner')}: ${json.TW[$(el).attr('owner')]['ERRORS']['MSG']}`);
					showNotyfications(`Ошибка TW в аккаунте ${$(el).attr('owner')}: ${json.TW[$(el).attr('owner')]['ERRORS']['MSG']}`, {type: 'error'});
				} else {
					loadSocialJson(false, json.TW[`${$(el).attr('owner')}`], $TW, templateTW);
				}
				break;
			default:
		}
	});
}

if ($arrSocial.length) {
	createSocialNetworks();
}

// DROPDOWN
$arrSocial.on('click', '.social-network__dropdown-arrow', function (e) {
	e.preventDefault();
	let $openedDropdown = $('.social-network__dropdown-content--opened'),
		$openedDropdownArrow = $openedDropdown.siblings('.social-network__dropdown-arrow');

	if (!$(this).hasClass('social-network__dropdown-arrow--active')) {
		$openedDropdown.removeClass('social-network__dropdown-content--opened');
		$openedDropdownArrow.removeClass('social-network__dropdown-arrow--active');
	}

	$(this).toggleClass('social-network__dropdown-arrow--active');
	$(this)
		.closest('.social-network__dropdown')
		.find('.social-network__dropdown-content')
		.toggleClass('social-network__dropdown-content--opened');

});

$arrSocial.find('.social-network__dropdown').on('click', '.social-network__title-name', function (e) {
	e.preventDefault();
	let $dropdown = $(this).closest('.social-network__dropdown'),
		$title = $dropdown.closest('.social-network__title'),
		$oldLink = $title.find('.social-network__title-name').eq(0),
		$newLink = $(this),
		$obj = $title.closest('.social-network');

	$oldLink
		.detach()
		.appendTo($dropdown.find('.social-network__dropdown-content'));

	$newLink
		.detach()
		.insertBefore($dropdown);

	$dropdown
		.find('.social-network__dropdown-arrow')
		.removeClass('social-network__dropdown-arrow--active')
		.siblings('.social-network__dropdown-content')
		.removeClass('social-network__dropdown-content--opened');

	$obj.attr({
		owner: $newLink.data('id'),
		pagen_1: 1,
	});

	$obj.find('.mCSB_container').html('<div class="soc__preloader"><img src="/images/svg/loader.svg"></div>');

	let params = {};
	params.owner = $obj.attr('owner');
	params.social_type = $obj.attr('data-soc-id');
	params.pagen_1 = $obj.attr('pagen_1');
	params.page_size = $obj.attr('page_size');
	params.current_page = '';
	params.next_page = '';
	params.paging_token = '';

	let paramsStr = getParamsStr(params);
	// console.log(paramsStr);
	getJson(`/news-and-media/social-networks/?${paramsStr}`)
		.then(function (data) {
			let json = JSON.parse(JSON.stringify(eval('(' + data + ')'))),
				singleJson, $objP, ejsTemplateName;

			// console.log(`Пришел ответ:`);
			// console.log(json);

			switch ($obj.data('soc-id')) {
				case 'fb':
					singleJson = json.FB[`${$obj.attr('owner')}`];
					$objP = $FB;
					ejsTemplateName = templateFB;
					break;
				case 'vk':
					singleJson = json.VK[`${$obj.attr('owner')}`];
					$objP = $VK;
					ejsTemplateName = templateVK;
					break;
				case 'ig':
					singleJson = json.IG[`${$obj.attr('owner')}`];
					$objP = $IG;
					ejsTemplateName = templateIG;
					break;
				case 'yt':
					singleJson = json.YT[`${$obj.attr('owner')}`];
					$objP = $YT;
					ejsTemplateName = templateYT;
					break;
				case 'tw':
					singleJson = json.TW[`${$obj.attr('owner')}`];
					$objP = $TW;
					ejsTemplateName = templateTW;
					break;
				default:
			}

			loadSocialJson(true, singleJson, $objP, ejsTemplateName);
			return singleJson;
		})
		.catch(function (error) {
			console.error('Error: ' + error);
			showNotyfications(error, {type: 'error'});
		});
});

$(document).on('click', function (e) {
	let $el = $('.social-network__dropdown-content--opened'),
		$elArrow = $el.siblings('.social-network__dropdown-arrow');
	if (!$el.is(e.target) && $el.has(e.target).length === 0 && !$elArrow.is(e.target) && $elArrow.has(e.target).length === 0) {
		$el.removeClass('social-network__dropdown-content--opened');
		$elArrow.removeClass('social-network__dropdown-arrow--active');
	}
});
