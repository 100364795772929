module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


	for (var prop in data[Object.keys(data)[0]]) {
			if (prop !== 'pagination') {
				var propObj = data[Object.keys(data)[0]][prop],
					photoObj = propObj.PHOTO,
					LANG = getLang();

			const moment = require('moment');
			moment.locale(LANG);
			var dateObj = moment(propObj.DATE).format('DD MMMM YYYY, HH:mm');
;
__p += '\n			<div class="social-network__item">\n				<span class="social-network__item-date">' +
((__t = (dateObj)) == null ? '' : __t) +
'</span>\n				<div class="social-network__item-content">\n					<p>' +
((__t = (propObj.TEXT)) == null ? '' : __t) +
'</p>\n				</div>\n			';

				var matchingKeys;
				var sizeKeys = Object.keys(photoObj);
				matchingKeys = sizeKeys.filter(function(size) {
					return (size >= 480 && size <= 1024);
				});
				if (matchingKeys.length && sizeKeys.length) {
					var imgMediumSrc = photoObj[matchingKeys[matchingKeys.length-1]];
				} else {
					var imgMediumSrc = photoObj[sizeKeys[sizeKeys.length-1]];
				}
				if (matchingKeys !== false) {
			;
__p += '\n				<a class="social-network__item-img" href="' +
((__t = (propObj.LINK)) == null ? '' : __t) +
'" target="_blank">\n					<img src="' +
((__t = (imgMediumSrc)) == null ? '' : __t) +
'" alt="" class="one-whole">\n				</a>\n			';

				}
				matchingKeys = false;
			;
__p += '\n\n			</div>\n';

		}
	}
;
__p += '\n';

}
return __p
}