const verge = require('verge');
var $bodElement = $('.bod__item');

if ($bodElement.length > 0) {
	var $items = $bodElement,
		$itemExp = $('.bod-expanded').eq(0),
		$close = $('.bod-close');

	function openBOD(object) {
		var $this = $(object);
		if (!$this.hasClass('bod__item--exp')) {
			var img = $this.find('img').attr('src'),
				name = $this.find('.bod__item-name').html(),
				position = $this.find('.bod__item-position').html(),
				additional = $this.find('.bod__item-additional').html(),
				desc = $this.find('.bod__item-description').html(),
				hashCode = $this.attr('data-id');
			$items.removeClass('bod__item--exp');
			$this.addClass('bod__item--exp');
			$itemExp
				.find('.bod-expanded__photo')
				.attr('src', img);
			$itemExp
				.find('.bod-expanded__name')
				.html(name);
			$itemExp
				.find('.bod-expanded__position')
				.html(position);
			$itemExp
				.find('.bod-expanded__additional')
				.html(additional);
			$itemExp
				.find('.bod-expanded__description')
				.html(desc);
			$itemExp.slideDown();
			$('body').addClass('scroll-lock');
			
			window.location.hash = hashCode;

			if (verge.viewportW() > 768) {
				$('html, body').animate({
					scrollTop: $itemExp.offset().top - 32 + 'px'
				}, 300);
			}
		}
	}

	function checkBodHash() {
		if (window.location.hash.length > 0 && !(window.location.hash == '#!')) {
			var hash = window.location.hash.substr(1);
			if ($('.bod__item[data-id=' + hash + ']').length > 0) {
				var tabHash = $('.bod__item[data-id=' + hash + ']').closest('.tabs__article').attr('data-tab')
				var $thisTabs = $('.bod__item[data-id=' + hash + ']').closest('.tabs');

				$thisTabs
					.children('.tabs__button')
					.removeClass('is-expand')
					.closest('a[href="#' + tabHash + '"]')
					.addClass('is-expand')
					.closest('.tabs')
					.children('.tabs__article')
					.removeClass('is-expand')
					.closest('[data-tab="' + tabHash + '"]')
					.addClass('is-expand');

				openBOD('.bod__item[data-id=' + hash + ']');
			}
		}
	}

	if ($bodElement.length) {
		checkBodHash();
	}

	$(window).on('hashchange', function () {
		if ($bodElement.length) {
			checkBodHash();
		}
	});

	$items.on('click', function () {
		window.location.hash = $(this).attr('data-id');
	});

	$close.click(function () {
		$itemExp.slideUp();
		$items.removeClass('bod__item--exp');
		$('body').removeClass('scroll-lock');
		history.pushState("", document.title, window.location.pathname + window.location.search)
	});

	$(document).on('keydown', function (e) {
		if (e.keyCode == 27) {
			$itemExp.slideUp();
			$items.removeClass('bod__item--exp');
			$('body').removeClass('scroll-lock');
			history.pushState("", document.title, window.location.pathname + window.location.search)
		}
	});
}