var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "filter-timeline" }, [
    _c(
      "ul",
      {
        staticClass: "filter-timeline__list--years filter-timeline__list--open"
      },
      [
        _c(
          "li",
          { staticClass: "filter-timeline__item filter-timeline__item--all" },
          [
            _c(
              "a",
              {
                staticClass: "filter-timeline__link filter-timeline__link--all",
                class: {
                  "filter-timeline__link--active": _vm.activeYear == "all"
                },
                attrs: { href: "javascript: void(0);" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.changeYear("all")
                  }
                }
              },
              [
                _c("span", { staticClass: "filter-timeline__text" }, [
                  _vm._v(_vm._s(_vm.text.all))
                ])
              ]
            )
          ]
        ),
        _vm._v(" "),
        _vm._l(_vm.years, function(item) {
          return _c("li", { staticClass: "filter-timeline__item" }, [
            _c(
              "a",
              {
                staticClass: "filter-timeline__link",
                class: {
                  disabled: item.disabled,
                  "filter-timeline__link--active": item.id == _vm.activeYear
                },
                attrs: { href: "javascript: void(0);" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.changeYear(item.id)
                  }
                }
              },
              [
                _c("span", { staticClass: "filter-timeline__text" }, [
                  _vm._v(_vm._s(item.id))
                ])
              ]
            )
          ])
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-68631388", { render: render, staticRenderFns: staticRenderFns })
  }
}