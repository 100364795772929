$( document ).ready(function() {

    $('.structure-item__tooltip').tooltipster({
        maxWidth: 500,
        speed: 300,
        interactive: true,
        theme: ['tooltipster-light'],
        trigger: 'click',
        side: 'bottom',
        functionPosition: function (instance, helper, position) {
            var leftPost = position.coord.left + position.size.width/2 - 10;
            if (leftPost <= $('body').outerWidth() - position.size.width) {
                position.coord.left = leftPost;
            }
            return position;
        }
    });

});
