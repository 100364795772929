var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "media-album row" },
    _vm._l(_vm.items, function(item) {
      return _c(
        "div",
        { staticClass: "col-default-4 col-tablet-6 col-mobile-12" },
        [
          _c(
            "figure",
            {
              staticClass:
                "media__item media__item--album figure figure--gradient u-margin--vetical"
            },
            [
              _c("div", { staticClass: "figure__content" }, [
                _c("img", {
                  staticClass: "figure__image",
                  attrs: { src: item.image, "data-object-fit": "cover" }
                })
              ]),
              _vm._v(" "),
              _c(
                "figcaption",
                { staticClass: "figure__caption media__caption" },
                [
                  _c("span", { staticClass: "figure__text" }, [
                    _vm._v(_vm._s(item.name))
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "media__add" }, [
                _c("a", {
                  staticClass: "media__zoom",
                  attrs: { href: item.link, title: _vm.text.tip }
                })
              ])
            ]
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6a0390bc", { render: render, staticRenderFns: staticRenderFns })
  }
}