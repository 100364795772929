<template>
    <div class="media-album row">
        <div v-for="item in items" class="col-default-4 col-tablet-6 col-mobile-12">
            <figure class="media__item media__item--album figure figure--gradient u-margin--vetical">
                <div class="figure__content">
                    <img class="figure__image" :src="item.image" data-object-fit="cover">
                </div>
                <figcaption class="figure__caption media__caption">
                    <span class="figure__text">{{ item.name }}</span>
                </figcaption>
                <div class="media__add">
                    <a
                        :href="item.link"
                        :title="text.tip"
                        class="media__zoom"
                    ></a>
                </div>
            </figure>
        </div>
    </div>
</template>
<script>
export default {
    name: 'media-albums',
    props: {
        items: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            text: {
                tip: this.$parent.lang == 'ru' ? 'Просмотреть альбом' : 'Show album'
            }
        }
    }
};
</script>