let triggerName;

const tooltips = Array.from(document.querySelectorAll('.tooltip'));

tooltips.forEach(item => {
	triggerName = item.getAttribute('trigger') || 'hover';

	if ($('html').hasClass('bx-touch')) {
		triggerName = 'click';
	}

	$(item).tooltipster({
		maxWidth: 500,
		speed: 300,
		interactive: true,
		theme: ['tooltipster-light'],
		animation: 'grow',
		trigger: triggerName,
		position: 'right'
	});
})

$('.js-hack-link').on('click', function () {
	window.open($(this).data('href'), '_blank');
});

$('.tooltip').each(function () {
	if ($(this).outerWidth() >= 280) {
		console.log($(this));
		$(this).css('display', 'inline');
	}
});