const $yearContainer = $('.js-year-container'),
	$yearLink = $yearContainer.find('.js-year__link'),
	$yearItem = $yearContainer.find('.year-item'),
	$ajaxHandler = $yearContainer.data('ajax-handler');

function checkYearHash() {
	if (window.location.hash.length > 0 && !(window.location.hash == '#!')) {
		var hash = window.location.hash.substr(1);
		if ($yearContainer.find('.year-item[data-id=' + hash + ']').length > 0) {
			openYearItem($yearContainer.find('.year-item[data-id=' + hash + ']'));
		}
	} else {
		if ($yearContainer.find('.year-item').eq(0).length > 0) {
			openYearItem($yearContainer.find('.year-item').eq(0));
		}
	}
}

function openYearItem($object) {
	let $this = $object;
	if (!$this.hasClass('year-item--open')) {
		$yearContainer.find('.year-item.year-item--open')
			.removeClass('year-item--open');
		$yearContainer.find('.js-year__link.filter-timeline__link--open')
			.removeClass('filter-timeline__link--open');

		$yearContainer.find(`.js-year__link[href="#${$this.attr('data-id')}"]`)
			.addClass('filter-timeline__link--open');
		$this
			.addClass('year-item--open');

		if ($ajaxHandler !== undefined) {
			loadContenYear($this.attr('data-id'));
			$yearContainer
					.find('.year-item[data-id=' + $this.attr('data-id') + ']')
					.html('<div class="year__preloader"><img src="/images/svg/loader.svg"></div>');
		}
	}
}

function loadContenYear(year) {
	getJson(`${$ajaxHandler}?year=${year}`)
		.then(function (data) {
			if ($yearContainer.find('.year-item[data-id=' + year + ']').length > 0) {
				if (data == '') {
					data = 'Content not found';
				}
				$yearContainer
					.find('.year-item[data-id=' + year + ']')
					.html(data);
			}
			$('.js-table-responsive').each(function (index, el) {
				var $this = $(this),
					typeTable = $this.attr('data-table-type'),
					$thisTable = $this.find('table');

				$thisTable
					.attr('data-tablesaw-mode', typeTable)
					.trigger('enhance.tablesaw');
			});
			$(document).trigger('enhance.tablesaw');
		});
}

if ($yearContainer.length) {
	checkYearHash();
}

$(window).on('hashchange', function(){
	if ($yearContainer.length) {
		checkYearHash();
	}
});
