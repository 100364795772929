module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (getLang() == 'ru') {
	moment.locale('ru');
}
;
__p += '\n\n';

	data.forEach(function (el) {
;
__p += '\n		<tr class="events__tr events__tr--' +
((__t = (el.type)) == null ? '' : __t) +
'" data-id="' +
((__t = (el.id)) == null ? '' : __t) +
'">\n			<td>\n				';

					var arDates = el.date.split('-'),
							outDate = '';
					if (arDates.length > 1) {
						var date1 = moment.unix(parseInt(arDates[0])).format("DD MMMM YYYY"),
								date2 = moment.unix(parseInt(arDates[1])).format("DD MMMM YYYY");

						if (moment.unix(parseInt(arDates[0])).format('YYYY') == moment.unix(parseInt(arDates[1])).format('YYYY')) {
							date1 = moment.unix(parseInt(arDates[0])).format("DD MMMM");
							if (moment.unix(parseInt(arDates[0])).format('MM') == moment.unix(parseInt(arDates[1])).format('MM')) {
								date1 = moment.unix(parseInt(arDates[0])).format("DD");
							}
						}
						outDate = date1 + ' - ' + date2;
					} else {
						outDate = moment.unix(parseInt(el.date)).format("DD MMMM YYYY");
					}
				;
__p += '\n				' +
((__t = ( outDate)) == null ? '' : __t) +
'\n			</td>\n			<td>' +
((__t = (el.name)) == null ? '' : __t) +
'</td>\n			<td>\n				';
 if (el.hasOwnProperty('additions')) { ;
__p += '\n					<ul class="events__additions">\n						';
 if (el.additions.hasOwnProperty('press-release')) { ;
__p += '\n						<li>\n							<a href=\'' +
((__t = ( el.additions["press-release"])) == null ? '' : __t) +
'\' class="link link--revert mountain-mist-text file-list__link u-size--1" target="_blank"><i>' +
((__t = ( localize({
										ru: 'Пресс-релиз',
										en: 'Press-release'
									}))) == null ? '' : __t) +
'</i></a>\n						</li>\n						';
 } ;
__p += '\n						';
 if (el.additions.hasOwnProperty('video')) { ;
__p += '\n						<li>\n							<a href=\'' +
((__t = ( el.additions["video"])) == null ? '' : __t) +
'\' class="link link--revert mountain-mist-text file-list__link u-size--1" target="_blank"><i>' +
((__t = ( localize({
										ru: 'Видео',
										en: 'Video'
									}))) == null ? '' : __t) +
'</i></a>\n						</li>\n						';
 } ;
__p += '\n						';
 if (el.additions.hasOwnProperty('presentation')) { ;
__p += '\n						<li>\n							<a href=\'' +
((__t = ( el.additions["presentation"])) == null ? '' : __t) +
'\' class="link link--revert mountain-mist-text file-list__link u-size--1" target="_blank"><i>' +
((__t = ( localize({
										ru: 'Презентация',
										en: 'Presentation'
									}))) == null ? '' : __t) +
'</i></a>\n						</li>\n						';
 } ;
__p += '\n					</ul>\n				';
 } ;
__p += '\n			</td>\n				<td>\n					';
 if (el.hasOwnProperty('venue')) { ;
__p += '\n						' +
((__t = (el.venue)) == null ? '' : __t) +
'\n					';
 } ;
__p += '\n				</td>\n		</tr>\n';

	})
;
__p += '\n';

}
return __p
}