module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


	const fbTitle = localize({
					ru: 'Facebook',
					en: 'Facebook'
				}),
				twTitle = localize({
					ru: 'Twitter',
					en: 'Twitter'
				}),
				vkTitle = localize({
					ru: 'ВКонтакте',
					en: 'VK'
				});
;
__p += '\n<ul class="events__popup-list ul--clear">\n	<li class="events__popup-item">\n		<a href="' +
((__t = ( data.links.fb )) == null ? '' : __t) +
'" target="_blank" class="events__popup-link link link--revert events__popup-link--fb">\n			<span>' +
((__t = (fbTitle)) == null ? '' : __t) +
'</span>\n		</a>\n	</li>\n	<li class="events__popup-item">\n		<a href="' +
((__t = ( data.links.tw )) == null ? '' : __t) +
'" target="_blank" class="events__popup-link link link--revert events__popup-link--tw">\n			<span>' +
((__t = (twTitle)) == null ? '' : __t) +
'</span>\n		</a>\n	</li>\n	<li class="events__popup-item">\n		<a href="' +
((__t = ( data.links.vk )) == null ? '' : __t) +
'" target="_blank" class="events__popup-link link link--revert events__popup-link--vk">\n			<span>' +
((__t = (vkTitle)) == null ? '' : __t) +
'</span>\n		</a>\n	</li>\n</ul>';

}
return __p
}