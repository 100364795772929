const debounce = require('throttle-debounce/debounce'),
	throttle = require('throttle-debounce/throttle'),
	verge = require('verge'),
	moment = require('moment'),
	$media = $('.js-media'),
	$mediaFilter = $('#filter'),
	$mediaTimeline = $('#filter-timeline'),
	$mediaItems = $media.find('.js-media__items'),
	templateElements = require('./media-elements.ejs'),
	templateSingleElement = require('./media-single-element.ejs'),
	templateFilter = require('./media-filter.ejs'),
	templateAlbums = require('./media-albums.ejs');
import {initGallery} from './initGallery.js';
let $preloader = $(`<div class="col-default-12 media__preloader"><img src="/images/svg/loader.svg"></div>`),
	$preloaderFilter = $preloader.clone(),
	$preloaderTimeline = $preloader.clone(),
	$scrollBottom = $(`<div class="col-default-12 media__scroll--bottom"><img src="/images/svg/icon_scroll-bottom.svg"></div>`),
	$scrollTop = $(`<div class="col-default-12 media__scroll--top"><img src="/images/svg/icon_scroll-top.svg"></div>`);
 
function getParamsStr(params) {
	params.type = Array.from(params.type, x => x.attributes['data-filter-id'].value);
	params.subject = Array.from(params.subject, x => x.attributes['data-filter-id'].value);

	return `AJAX_CALL=Y&viewMode=${encodeURIComponent(params.viewMode)}&PAGEN_1=${encodeURIComponent(params.pageNumber)}&section=${encodeURIComponent(params.section)}&type=${encodeURIComponent(params.type)}&subject=${encodeURIComponent(params.subject)}&search=${encodeURIComponent(params.search)}&dateStart=${encodeURIComponent(params.dateStart)}&dateEnd=${encodeURIComponent(params.dateEnd)}`;
}

function getCurrentParams() {
	return {
		viewMode: $media.attr('data-mode'),
		pageNumber: parseInt($media.attr('data-page-current')),
		type: $media
			.find('[data-filter-container="type"]')
			.find('.filter-item__filter-link--active'),
		subject: $media
			.find('[data-filter-container="subject"]')
			.find('.filter-item__filter-link--active'),
		dateStart: parseInt($media.attr('data-page-start')),
		dateEnd: parseInt($media.attr('data-page-end')),
		section: $media.attr('data-section')
	};
}

function setParams(mode = 'albums', current, total, dateStart, dateEnd, section) {
	$media
		.attr('data-mode', mode)
		.attr('data-page-current', current);

	if (total) {
		$media.attr('data-page-total', total);
	}

	if (dateStart) {
		$media.attr('data-page-start', dateStart);
	}

	if (dateEnd) {
		$media.attr('data-page-end', dateEnd);
	}

	if (section) {
		$media.attr('data-section', section);
	}

	let $actualEl = $mediaTimeline.find($(`[data-timestamp-start="${dateStart}"]`)),
		$activeEl = $mediaTimeline.find($('.filter-timeline__link--active'));

	if (!$actualEl.is(':visible')) {
		if ($actualEl.hasClass('filter-timeline__link--years')) {
			let	$openedYear = $media.find('#filter-timeline--years')
													.find('.filter-timeline__link.filter-timeline__link--open'),
				$openedYearUL = $media.find('#filter-timeline--years')
														.find('.filter-timeline__list--2.filter-timeline__list--open');

			if ($openedYearUL.length) {
				$openedYear
					.removeClass('filter-timeline__link--open');
				$openedYearUL
					.removeClass('filter-timeline__list--open');
			}

			$actualEl
				.closest('.filter-timeline__list--2')
				.siblings('.js-timeline__link--years')
				.addClass('filter-timeline__link--open');

			$actualEl
				.closest('.filter-timeline__list--2')
				.addClass('filter-timeline__list--open');

			if (!$actualEl.is(':visible')) {
				$('.js-timeline__more').trigger('click');
			}
		}
	}

	if (!($actualEl == $activeEl)) {
		$activeEl.removeClass('filter-timeline__link--active');
		$actualEl.addClass('filter-timeline__link--active');
	}

	// $preloaderTimeline.remove();

	$mediaFilter.find('.filter__group').each(function () {
		checkAllActiveFilter($(this));
	});
}

function createAlbums() {
	let json = arAlbums;

	$mediaItems
		.find($preloader)
		.remove();

	$mediaItems
		.children('.js-media__items-row')
		.html(templateAlbums(json));

	objectFitPolyfill();

	setParams('albums', arAlbums.page.current, arAlbums.page.total, 46800, moment().endOf('day').unix());

	if (arAlbums.page.current < arAlbums.page.total) {
		$scrollBottom
			.appendTo($mediaItems.children('.js-media__items-row'));
	}
}

function createElements() {
	let json = arAlbums;

	$mediaItems
		.find($preloader)
		.remove();

	$mediaItems
		.children('.js-media__items-row')
		.html(templateElements(json));

	objectFitPolyfill();

	if (getUrlParam('section') == null) {
		setParams('photos', arAlbums.page.current, arAlbums.page.total, 46800, moment().endOf('day').unix());
	} else {
		setParams('photos', arAlbums.page.current, arAlbums.page.total, 46800, moment().endOf('day').unix(), getUrlParam('section'));

		let albumKey = localize({
				ru: 'Альбом',
				en: 'Album'
			}),
			backToAlbumKey = localize({
				ru: 'Вернуться к альбомам',
				en: 'Back to albums'
			});
		$(`<div class="row middle-default">
				<div class="col-default-6">
					<h2>${albumKey}: ${decodeURI(getUrlParam('sectionName'))}</h2>
				</div>
				<div class="col-default-6 u-align-right">
					<a href="${document.location.pathname}" class="btn btn--bordered">${backToAlbumKey}</a>
				</div>
			</div>`).prependTo($mediaItems);
	}

	if (arAlbums.page.current < arAlbums.page.total) {
		$scrollBottom
			.appendTo($mediaItems.children('.js-media__items-row'));
	}
}

function createElementsFilter() {
	let paramsFilter;

	if ($('#media-load-mode').length && $('#media-load-mode')[0].hasAttribute('data-timeline-filter')) {
		paramsFilter = $('#media-load-mode').attr('data-timeline-filter');
	} else {
		paramsFilter = 'clearState=Y';
	}
	getJson(`/local/templates/nornik/components/bitrix/news.list/media-library/ajax.php?AJAX_CALL=Y&viewMode=photos&${paramsFilter}`)
		.then(function (data) {
			let json = data;
			json = JSON.parse(JSON.stringify(eval('(' + json + ')')));

			// console.log(`Пришел ответ для фильтра:`);
			// console.log(json);

			$mediaFilter
				.html(templateFilter(json));

			return json;
		})
		.catch(function (error) {
			console.error('Error: ' + error);
			showNotyfications(error, {type: 'error'});
		});

}

function createSingleElement(object) {
	let $this = object,
		type = $this.attr('data-type') || '',
		subject = $this.attr('data-subject') || '';

	getJson(`/local/templates/nornik/components/bitrix/news.list/media-library/ajax.php?AJAX_CALL=Y&viewMode=photos&type=${type}&subject=${subject}&PAGEN_1=1`)
		.then(function (data) {
			let json = data;
			json = JSON.parse(JSON.stringify(eval('(' + json + ')')));

			$this
				.html(templateSingleElement(json.items[0]));

			objectFitPolyfill();
		})
		.catch(function (error) {
			console.error('Error: ' + error);
			showNotyfications(error, {type: 'error'});
		});
}

global.loadMediaAlbumsAjax = function (state) {
	if (!state) {
		var direction = 'new';
	} else {
		var direction = state;
	}
	let params = getCurrentParams(),
		totalPages = parseInt($media.attr('data-page-total'));
	if (params.pageNumber < totalPages) {
		params.pageNumber = parseInt(params.pageNumber) + 1;
	}

	let paramsStr = getParamsStr(params);
	// console.log(`---------------------------------------------------------------------`);
	// console.log(`Передаю следующую строку в параметрах: ${paramsStr}`);

	getJson(`/news-and-media/media-library/?${paramsStr}`)
		.then(function (data) {
			let json = data;
			json = JSON.parse(JSON.stringify(eval('(' + json + ')')));

			// console.log(`Пришел ответ:`);
			// console.log(json);

			if (direction == 'next') {
				$mediaItems
					.children('.js-media__items-row')
					.append(templateAlbums(json));
			} else if (direction == 'prev') {
				$mediaItems
					.children('.js-media__items-row')
					.prepend(templateAlbums(json));
			} else {
				$mediaItems
					.children('.js-media__items-row')
					.html(templateAlbums(json));
			}
			return json;
		})
		.then(function (json) {
			initGallery('update');
			objectFitPolyfill();

			$preloader
				.remove();
			setParams('albums', json.page.current, json.page.total, 46800, moment().endOf('day').unix());

			if (parseInt(json.page.current) < parseInt(json.page.total)) {
				$scrollBottom
					.appendTo($mediaItems.children('.js-media__items-row'));
			}
		})
		.catch(function (error) {
			console.error('Error: ' + error);
			showNotyfications(error, {type: 'error'});
		});
};

global.loadMediaElementsAjax = function (state) {
	let $this = $(this),
		params = getCurrentParams(),
		totalPages = parseInt($media.attr('data-page-total'));

	if (!state) {
		var direction = 'new';
	} else {
		var direction = state;
	}

	if (direction == 'next') {
		if (params.pageNumber < totalPages) {
			params.pageNumber = parseInt(params.pageNumber) + 1;
		}
	} else if (direction == 'prev') {
		if (params.pageNumber > 1) {
			params.pageNumber = parseInt(params.pageNumber) - 1;
		}
	} else {
		params.pageNumber = 1;
	}

	if ($this.attr('data-timestamp-end') && $this.attr('data-timestamp-start')) {
		params.dateStart = parseInt($this.attr('data-timestamp-start'));
		params.dateEnd = parseInt($this.attr('data-timestamp-end'));
	}

	if (params.dateStart == undefined || params.dateStart !== params.dateStart) {
		params.dateStart = 46800;
	}

	if (params.dateEnd == undefined || params.dateEnd !== params.dateEnd) {
		params.dateEnd = moment().endOf('day').unix();
	}

	let paramsStr = getParamsStr(params);
	// console.log(`---------------------------------------------------------------------`);
	// console.log(`Передаю следующую строку в параметрах: ${paramsStr}`);

	getJson(`/local/templates/nornik/components/bitrix/news.list/media-library/ajax.php?${paramsStr}`)
		.then(function (data) {
			let json = data;
			json = JSON.parse(JSON.stringify(eval('(' + json + ')')));

			// console.log(`Пришел ответ:`);
			// console.log(json);

			if (direction == 'next') {
				$mediaItems
					.children('.js-media__items-row')
					.append(templateElements(json));
			} else if (direction == 'prev') {
				$mediaItems
					.children('.js-media__items-row')
					.prepend(templateElements(json));
			} else {
				$mediaItems
					.children('.js-media__items-row')
					.html(templateElements(json));
			}
			return json;
		})
		.then(function (json) {
			initGallery('update');
			objectFitPolyfill();

			$preloader
				.remove();
			setParams('photos', json.page.current, json.page.total, json.date.dateStart, json.date.dateEnd);

			if (parseInt(json.page.current) < parseInt(json.page.total)) {
				$scrollBottom
					.appendTo($mediaItems.children('.js-media__items-row'));
			}
		})
		.catch(function (error) {
			console.error('Error: ' + error);
			showNotyfications(error, {type: 'error'});
		});
};

let $singleEl = $('.js-media-single-element');
if ($singleEl.length) {
	$singleEl.each(function () {
		createSingleElement($(this));
	});
}

if ($media.length) {
	$mediaItems
		.children('.js-media__items-row')
		.html($preloader);

	$mediaFilter.html($preloaderFilter);
	$(document).find('#filter-timeline--years').html($preloaderTimeline);

	if ($('#media-load-mode').length && $('#media-load-mode').data('load-mode') == 'albums') {
		createAlbums();
	} else {
		createElements();
	}

	if (!($('#media-load-mode').length && $('#media-load-mode').data('hide-filter') == 'yes')) {
		$('#filter-controls').removeClass('filter--hide-filter');
	}

	createElementsFilter();
	require('./media-timeline.js');

	removeParams(['tags', 'PAGEN_1', 'AJAX_CALL', 'section', 'sectionName', 'viewMode']);
}

//
// filter handlers
//
//
$(document).on('click', '.js-link-album', function (e) {
	e.preventDefault();
	window.location.href = `http://${location.host}/news-and-media/media-library/?viewMode=photos&section=${$(this).data('section')}&sectionName=${$(this).text()}`;
});

$(document).on('click', '.js-media .js-filter__link', function (e) {
	let $this = $(this),
		$container = $this.closest('.filter__group');

	if ($this.hasClass('filter-item__filter-link--disabled')) {
		return false;
	}

	if ($this.hasClass('js-filter__all')) {
		$container
			.find('.filter-item__filter-link:not(.js-filter__all)')
			.addClass('filter-item__filter-link--active');
		$this.addClass('filter-item__filter-link--disabled');
	} else {
		$(this).toggleClass('filter-item__filter-link--active');
		checkAllActiveFilter($container);
	}
});

$(document).on('click', '.js-media .js-timeline__link, .js-media .js-filter__link', function (e) {
	let $this = $(this);
	if ($this.attr('data-timestamp-start') || $this.attr('data-filter-id')) {
		$mediaItems
			.children('.js-media__items-row')
			.html($preloader);
	}
	setParams('photos', 1);
});

$(document).on('click', '.js-media .js-timeline__link, .js-media .js-filter__link', debounce(1000, loadMediaElementsAjax));
//
//
// end filter handlers
//

//
// scroll logic
//
//
function goLoadPrev() {
	$scrollTop.remove();
	$mediaItems
		.children('.js-media__items-row')
		.prepend($preloader);
	loadMediaElementsAjax('prev');
}

function goLoadNext() {
	$scrollBottom.remove();
	$mediaItems
		.children('.js-media__items-row')
		.append($preloader);

	if ($media.attr('data-mode') == 'albums') {
		loadMediaAlbumsAjax('next');
	} else {
		loadMediaElementsAjax('next');
	}
}

function checkScroll(e) {
	let inViewportBottom = verge.inViewport($('.media__scroll--bottom'), -16);
	let inViewportTop = verge.inViewport($('.media__scroll--top'), 16);
	let startScrollTop = $(window).scrollTop();

	if (e.type == 'touchmove') {
		let currentY = e.originalEvent.touches[0].clientY;

		if (startY < currentY) {
			if (inViewportTop) {
				e.preventDefault();
				e.stopPropagation();
				goLoadPrev();
			}
		} else {
			if (inViewportBottom) {
				e.preventDefault();
				e.stopPropagation();
				goLoadNext();
			}
		}
		return false;

	} else if (e.type == 'mousewheel' || e.type == 'DOMMouseScroll') {
		if (e.originalEvent.wheelDelta > 0 || e.originalEvent.detail < 0) {
			if (inViewportTop) {
				e.preventDefault();
				e.stopPropagation();
				goLoadPrev();
			}
		} else {
			if (inViewportBottom) {
				e.preventDefault();
				e.stopPropagation();
				goLoadNext();
			}
		}
		return false;

	}
}

var startY;
$(document).bind('touchstart', function (e) {
	startY = e.originalEvent.touches[0].clientY;
});

$(document).on('touchmove scroll mousewheel DOMMouseScroll', throttle(250, function (e) {
	checkScroll(e);
}));

$(document).on('click', '.media__scroll--top > img', goLoadPrev);
$(document).on('click', '.media__scroll--bottom > img', goLoadNext);
//
//
// end scroll logic
//


// PRESENTATIONS PAGE

let $AnchorElement = $('.js-anchor-items');

function checkAnchorHash() {
	if (window.location.hash.length > 0 && !(window.location.hash == '#!')) {
		var hash = window.location.hash.substr(1);
		if ($('[data-id=' + hash + ']').length > 0) {
			let offs = 32;
			if ($(window).innerWidth() <= 768) {
				offs = 87;
			}
			$('html, body').animate({
				scrollTop: $('[data-id=' + hash + ']').offset().top - offs + 'px'
			}, 300);
		}
	}
}

if ($AnchorElement.length) {
	checkAnchorHash();
}

$(window).on('hashchange', function(e) {
	if ($AnchorElement.length) {
		e.preventDefault();
		checkAnchorHash();
	}
});

// Init plugin lg-gallery
if ($media.length) {
	initGallery();
}

$(function() {
	if ($media.length) {
		let hash = getHash().hash[0];
		if (hash.length) {
			let $el = $(`#js-media-gallery-${hash.substr(1)}`);
			if ($el.length) {
				$el.find('.js-media__zoom').trigger('click');
			}
		}
	}
});
