const $svgKY = $('.governance-structure__svg');
const throttle = require('throttle-debounce/throttle');

function showPopup($el) {
	let $popup;
	if ($el.hasClass('governance-structure__popup')) {
		$popup = $el;
	} else {
		$popup = $(`.governance-structure__popup[data-id="${$el.data('id')}"]`);
	}
	$popup.addClass('is-hover');
}
function hidePopups() {
	$(`.governance-structure__popup.is-hover`).removeClass('is-hover');
}

$('.governance-structure__lens')
	.on('mouseenter', throttle(0, function () {
		showPopup($(this));
	}))
	.on('mouseout', throttle(0, function (e) {
		if (!$(e.relatedTarget).hasClass('governance-structure__popup') && !$(e.relatedTarget).hasClass('governance-structure__lens')) {
			hidePopups();
		}
	}));

$(`.governance-structure__popup`)
	.on('mouseleave', throttle(0, function () {
		hidePopups();
	}));
