var $media = $('.js-media');
var $timelineYears = $media.find('#filter-timeline--years'),
	$preloader = $(`<div class="col-default-12 media__preloader"><img src="/images/svg/loader.svg"></div>`);
var templateTimeline = require('./media-timeline.ejs');

global.createMediaTimelineYears = function (params) {
	getJson(`/local/templates/nornik/components/bitrix/news.list/media-library/img-dates.php?${params}`)
		.then(function (data) {
			if (!data.length) {
				showNotyfications('Проблема с построением таймлайна, возможно нет файла img-dates.json', {type: 'error'});
				console.error('Проблема с построением таймлайна');
				return false;
			}

			let json = data;
			json = JSON.parse(JSON.stringify(eval('(' + json + ')')));

			// console.log(`Пришел ответ для таймлайна:`);
			// console.log(json);

			$timelineYears
				.html(templateTimeline(json))
				.mCustomScrollbar({
					scrollInertia: 500
				});

			return null;
		})
		.catch(function (error) {
			console.error('Error: ' + error);
			showNotyfications(error, {type: 'error'});
		});
};

if ($('#media-load-mode').length && $('#media-load-mode').data('timeline-filter')) {
	createMediaTimelineYears(`AJAX_CALL=Y&${$('#media-load-mode').data('timeline-filter')}`);
} else {
	createMediaTimelineYears('AJAX_CALL=Y');
}
