var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "media filter-container", class: _vm.template },
    [
      _vm.backLink
        ? _c(
            "a",
            { staticClass: "media__back-link", attrs: { href: _vm.backLink } },
            [_c("span", [_vm._v(_vm._s(_vm.text.backLink))])]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "mediaFilterControls",
          staticClass: "filter-controls",
          class: { "offset-top": _vm.offsetTop }
        },
        [
          _vm.filters.themes.length || _vm.filters.types.length
            ? _vm._t("filter", null, { type: _vm.type, filters: _vm.filters })
            : _vm._e(),
          _vm._v(" "),
          _vm.filters.years.length
            ? _c("media-years", {
                attrs: {
                  years: _vm.filters.years,
                  "default-year": _vm.defaultYear
                }
              })
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "media__content" },
        [
          _vm.notFound && !_vm.loading
            ? _c("div", { staticClass: "not-found" }, [
                _c("span", { staticClass: "not-found__title" }, [
                  _vm._v(_vm._s(_vm.text.notFoundTitle))
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "not-found__text" }, [
                  _vm._v(_vm._s(_vm.text.notFoundText))
                ])
              ])
            : !_vm.error && _vm.items.length
            ? _vm._t("content", null, { items: _vm.items })
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "preloader" }, [
            _c("img", { attrs: { src: "/images/svg/loader.svg" } })
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5fb8d502", { render: render, staticRenderFns: staticRenderFns })
  }
}