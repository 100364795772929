module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


	var moment = require('moment'),
		array = Object.keys(data),
		LANG = getLang();
;
__p += '\n\n<li class="filter-timeline__item filter-timeline__item--all">\n	<a href="#" class="filter-timeline__link filter-timeline__link--all js-timeline__link js-timeline__link--all js-timeline__link--years filter-timeline__link--active" data-id="all" data-timestamp-end="' +
((__t = (moment().endOf('day').unix() )) == null ? '' : __t) +
'" data-timestamp-start="46800">\n		<span class="filter-timeline__text">' +
((__t = ( (LANG == 'ru') ? 'Все' : 'All')) == null ? '' : __t) +
'</span>\n	</a>\n</li>\n';
 for(var i=array.length-1; i >= 0 ; i--) { ;
__p += '\n	<li class="filter-timeline__item">\n		<a href="#" class="filter-timeline__link js-timeline__link--years">\n			<span class="filter-timeline__text">' +
((__t = ( array[i] )) == null ? '' : __t) +
'</span>\n		</a>\n		<ul class="filter-timeline__list--2">\n		';

			var arrayMonth = Object.keys(data[array[i]]);
			arrayMonth.sort();
		;
__p += '\n			';
 for(var j=arrayMonth.length-1; j >= 0 ; j--) { ;
__p += '\n				<li class="filter-timeline__item filter-timeline__item--years">\n					<a href="#" class="filter-timeline__link filter-timeline__link--years js-timeline__link" data-timestamp-start="' +
((__t = ( data[array[i]][arrayMonth[j]].dateStart )) == null ? '' : __t) +
'" data-timestamp-end="' +
((__t = ( data[array[i]][arrayMonth[j]].dateEnd )) == null ? '' : __t) +
'">\n						' +
((__t = ( data[array[i]][arrayMonth[j]].monthName )) == null ? '' : __t) +
'\n					</a>\n				</li>\n			';
 } ;
__p += '\n		</ul>\n	</li>\n';
 } ;
__p += '\n';

}
return __p
}