export const $esgContainer = $('.js-esg-container');

function checkEsgHash() {
	if (window.location.hash.length > 0 && !(window.location.hash == '#!')) {
		var hash = window.location.hash.substr(1);
		if ($esgContainer.find('.esg-item[data-id=' + hash + ']').length > 0) {
			openEsgItem($esgContainer.find('.esg-item[data-id=' + hash + ']'));
		}
	} else {
		if ($esgContainer.find('.esg-item').eq(0).length > 0) {
			openEsgItem($esgContainer.find('.esg-item').eq(0));
		}
	}
}

export function openEsgItem($object) {
	let $this = $object;
	if (!$this.hasClass('esg-item--open')) {
		$esgContainer.find('.esg-item.esg-item--open')
			.removeClass('esg-item--open');
		$esgContainer.find('.js-esg__link.esg-nav__link--open')
			.removeClass('esg-nav__link--open');

		$esgContainer.find(`.js-esg__link[href="#${$this.attr('data-id')}"]`)
			.addClass('esg-nav__link--open');
		$this
			.addClass('esg-item--open');
	}
}

if ($esgContainer.length) {
	checkEsgHash();

	$(document).on('click', '.aside__lang', function (e) {
		e.preventDefault();
		window.location.href = $(this).attr('href') + getHash().href;
	});
}

$(window).on('hashchange', function () {
	if ($esgContainer.length) {
		checkEsgHash();
	}
});
