module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


	const gTitle = localize({
					ru: 'Google календарь',
					en: 'Google calendar'
				}),
				yTitle = localize({
					ru: 'Yahoo календарь',
					en: 'Yahoo calendar'
				}),
				oTitle = localize({
					ru: 'Outlook календарь',
					en: 'Outlook calendar'
				}),
				iTitle = localize({
					ru: 'iCal календарь',
					en: 'iCal calendar'
				});
;
__p += '\n<ul class="events__popup-list ul--clear">\n	<li class="events__popup-item">\n		<a href="' +
((__t = ( data.links.google )) == null ? '' : __t) +
'" target="_blank" class="events__popup-link link link--revert events__popup-link--google">\n			<span>' +
((__t = (gTitle)) == null ? '' : __t) +
'</span>\n		</a>\n	</li>\n	<li class="events__popup-item">\n		<a href="' +
((__t = ( data.links.yahoo )) == null ? '' : __t) +
'" target="_blank" class="events__popup-link link link--revert events__popup-link--yahoo">\n			<span>' +
((__t = (yTitle)) == null ? '' : __t) +
'</span>\n		</a>\n	</li>\n	<li class="events__popup-item">\n		<a href="' +
((__t = ( data.links.outlook )) == null ? '' : __t) +
'" target="_blank" class="events__popup-link link link--revert events__popup-link--outlook">\n			<span>' +
((__t = (oTitle)) == null ? '' : __t) +
'</span>\n		</a>\n	</li>\n	<li class="events__popup-item">\n		<a href="' +
((__t = ( data.links.ical )) == null ? '' : __t) +
'" target="_blank" class="events__popup-link link link--revert events__popup-link--ical">\n			<span>' +
((__t = (iTitle)) == null ? '' : __t) +
'</span>\n		</a>\n	</li>\n</ul>';

}
return __p
}