$(document).ready(function () {
	//disable empty groups
	$('.dl--glossary').each(function () {
		if ($(this).children('.def__container').length == 0) {
			$('#letters-' + $(this).attr('id').substr(6)).addClass('glossary__link--disabled');
		}
	});

	//anchor navigation
	if (window.location.hash != '' && window.location.hash.charAt(1) !== '!') {
		var currenthash = window.location.hash.substr(1);
		var currenta = $('[id=letters-' + currenthash + ']');
		if (currenta.length) {
			selectGlossaryTab(currenthash, currenta);
		}
	}

	//tab selection:
	$('.glossary-wrapper').on('click', '[id^=letters-]', function (event) {
		event.preventDefault();
		var currenta = $(this);
		if (!currenta.hasClass('glossary__link--disabled') && !currenta.hasClass('glossary__link--active')) {
			var unic = $(this).attr('id').substr(8); //get unic identifier
			selectGlossaryTab(unic, currenta);
		}
	});
});

//open tab function
function selectGlossaryTab(unic, currenta) {
	removeParams('q');
	$('#search_q').val('');
	window.location.hash = unic;
	$('.glossary-wrapper .glossary__link--active').removeClass('glossary__link--active');
	$('[id^=terms-]').hide();
	$('#search-terms').hide();
	$('[id=terms-' + unic + ']').fadeIn(300);
	currenta.addClass('glossary__link--active');
}
