let enableHash = ($('.horizontal-slider').hasClass('js-hash-slider')) ? true : false;

const horSlider = new Swiper('.horizontal-slider', {
	direction: 'horizontal',
	speed: 400,
	pagination: '.horizontal-slider__pagination',
	paginationClickable: true,
	simulateTouch: false,
	spaceBetween: 0,
	hashnav: enableHash,
	hashnavWatchState: enableHash,
	replaceState: enableHash,
	onInit: function () {

	},
	onImagesReady: function () {
		// setTimeout(objectFitPolyfill, 1000);
		// setMinHeight();
	}
});