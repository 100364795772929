require('./contacts-page.js');

// FIXED SCROLL

function sidebarFixedArea(selector, scrollTop) {
	//fixed aside if window scrolled
	var $sidebarFixedArea = $(selector);
	if ($(window).scrollTop() >= scrollTop) {
		$sidebarFixedArea.addClass('is-fixed');
	} else {
		$sidebarFixedArea.removeClass('is-fixed');
	}
	$(window).on('scroll', function () {
		if ($(window).scrollTop() > scrollTop) {
			$sidebarFixedArea.addClass('is-fixed');
		} else {
			$sidebarFixedArea.removeClass('is-fixed');
		}
	});
}

$('.js-btn--form__open').on('click', function (e) {
	e.preventDefault();
	$('.js-form--sticky').addClass('form--sticky--opened');
});

$('.js-form__close').on('click', function (e) {
	e.preventDefault();
	$('.js-form--sticky').removeClass('form--sticky--opened');
});

$(document).on('click', function (e) {
	if ($('.js-form--sticky').hasClass('form--sticky--opened')) {
		if (!$('.js-form--sticky').is(e.target) && $('.js-form--sticky').has(e.target).length === 0 && !$('.js-btn--form__open').is(e.target) && $('.js-btn--form__open').has(e.target).length === 0) {
			$('.js-form--sticky').removeClass('form--sticky--opened');
		}
	}
});

$(document).on('keyup', function (e) {
	if (e.keyCode === 27 && $('.js-form--sticky').hasClass('form--sticky--opened')) {
		$('.js-form--sticky').removeClass('form--sticky--opened');
	}
});