const $pie = $('.pie');
const verge = require('verge');
const throttle = require('throttle-debounce/throttle');
const $group = $('.structure-group__item');

$pie.on('click', function () {
	let $this = $(this);
	if (!$this.hasClass('pie--selected')) {
		$('.pie.pie--selected').removeClass('pie--selected');
		$this.addClass('pie--selected');

		$('.structure-group-nav__item--opened').removeClass('structure-group-nav__item--opened');
		$(`.structure-group-nav__item[data-id="${$this.attr('id')}"]`).addClass('structure-group-nav__item--opened');

		$('.structure-group__item--open').removeClass('structure-group__item--open');
		$(`.structure-group__item[data-id="${$this.attr('id')}"]`).addClass('structure-group__item--open');
	}
});

$('.structure-group-nav__item').on('click', function () {
	let $this = $(this);

	if (!$this.hasClass('structure-group-nav__item--opened')) {
		$('.structure-group-nav__item--opened').removeClass('structure-group-nav__item--opened');
		$this.addClass('structure-group-nav__item--opened');

		$('.pie.pie--selected').removeClass('pie--selected');
		$(`.pie[id="${$this.attr('data-id')}"]`).addClass('pie--selected');

		$('.structure-group__item--open').removeClass('structure-group__item--open');
		$(`.structure-group__item[data-id="${$this.attr('data-id')}"]`).addClass('structure-group__item--open');
	}
});

// MEDIA

function tabletHTML() {
	$.each($group, function () {
		let $thead = $(this).find('thead'),
			$tr = $(this).find('tr');

		$thead.remove();
		$.each($tr, function () {
			let $td = $(this).find('td');
			$td
				.eq(0)
				.append($td.eq(1).html());

			$td
				.eq(0)
				.find('.td--row')
				.prepend('<p>Эффективная доля собственности</p>');

			$td
				.eq(1)
				.remove();
		});
	});
}

function desktopHTML() {
	$.each($group, function () {
		let $thead = $(`
				<thead>
					<tr>
						<th width="65%">Вид деятельности</th>
						<th width="35%">Эффективная<br>доля собственности</th>
					</tr>
				</thead>
			`),
			$tr = $(this).find('tbody').find('tr');

		$thead.prependTo($(this).find('table'));
		$.each($tr, function () {
			let $td = $(this).find('td'),
				$tdNew = $(`
					<td>
						<div class="td--row">
							${$td
									.find('.td--row')
									.html()
								}
						</div>
					</td>
				`);

			$td.find('.td--row').remove();
			$(this)
				.append($tdNew)
				.find('.td--row')
				.find('p')
				.remove();
		});
	});
}

$(window).resize(
	throttle(300, function () {
		if (!inWidth(1640)) {
			if (!$group.find('thead').length) {
				desktopHTML();
			}
		}
		if (inWidth(1640)) {
			if (!$group.find('.td--row p').length) {
				tabletHTML();
			}
		}
	})
);

if (!inWidth(1640)) {
	if (!$group.find('thead').length) {
		desktopHTML();
	}
}

if (inWidth(1640)) {
	if (!$group.find('.td--row p').length) {
		tabletHTML();
	}
}
