$('.js-share-toggle').on('click', function (event) {
	event.preventDefault();
	let $this = $(this),
		$container = $this.siblings('.js-share-container').children('.share-dialog');
	$this.toggleClass('share-switch--active');
	$container.toggleClass('share-dialog--open');
});

$('.bookmarks').on('click', 'a', function (event) {
	event.preventDefault();
	let $container = $(this).closest('.share-dialog'),
		$toggle = $container.parent().siblings('.js-share-toggle');
	$container.removeClass('share-dialog--open');
	$toggle.removeClass('share-switch--active');
});
