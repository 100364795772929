module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


	let titleShare = localize({
			ru: 'Поделиться',
			en: 'Share'
		}),
		titleZoom = localize({
			ru: 'Перейти к библиотеке материалов',
			en: 'Go to media library'
		}),
		titleDownload = localize({
			ru: 'Скачать',
			en: 'Download'
		}),
		textNotFound = localize({
			ru: 'Медиаматериалов за выбранный временной диапазон с данными параметрами фильтра не найдено',
			en: 'No media for the selected time range of the data filter settings'
		});
;
__p += '\n\n';

		let download, link, name, href, hrefHtml, platformVideo;

		// console.log(data);
		// debugger;
		if (data.propVideoLink == '' || data.propVideoLink == null) {
			href = 'href="'+ data.img.highImg.src + '"'
		} else {
			platformVideo = data.propVideoLink.match(/(http:\/\/|https:\/\/|)(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/g);
			if (platformVideo !== null) {
				href = 'href="'+ data.propVideoLink + '"'
			} else {
				href = 'data-html="#video-' + data.id + '-n-0"';
				hrefHtml = "<div style=\"display:none;\" id=\"video-" + data.id + "-n-0\"><video class=\"lg-video-object lg-html5 video-js vjs-default-skin\" controls preload=\"none\"><source src=\"" + data.propVideoLink + "\" type=\"video/mp4\">Your browser does not support HTML5 video.</video></div>";
			}
		}

		if (data.propDoc == '' || data.propDoc == null) {
			if (data.propType.value == 'Фото' || data.propType.value == 'Foto') {
				download = data.img.originalImg.src
			}
		} else {
			download = data.propDoc
		}

		if (!(data.propExtLink == '' || data.propExtLink == null)) {
			link = data.propExtLink
		}

		if (data.propName == '' || data.propName == null) {
			name = data.name
		} else {
			name = data.propName
		}
;
__p += '\n<figure class="media__item media__item--' +
((__t = (data.propType.xmlID )) == null ? '' : __t) +
' figure figure--gradient u-margin--vetical">\n	<div class="figure__content">\n		<img class="figure__image" src="' +
((__t = (data['img']['mediumImg']['src'] )) == null ? '' : __t) +
'" data-object-fit="cover">\n	</div>\n	<figcaption class="figure__caption media__caption">\n		<span class="figure__text">' +
((__t = (data.name )) == null ? '' : __t) +
'</span>\n		<time pubtime="" datetime="" class="figure__date"><i>' +
((__t = (data.dateFormatted )) == null ? '' : __t) +
'</i></time>\n	</figcaption>\n	<div class="media__add">\n		<a href="/news-and-media/media-library/" target="_blank" class="media__zoom js-media__zoom" title="' +
((__t = (titleZoom )) == null ? '' : __t) +
'"></a>\n	</div>\n</figure>\n';

}
return __p
}