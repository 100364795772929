const moment = require('moment');
const $arStockCharts = $('.js-stockchart');

function editValues(str, dec) {
	let out = parseFloat(str).toFixed(dec);
	if (getLang() == 'ru') {
		out = out.replace(/\./g, ',');
		out = out.replace(/\B(?=(\d{3})+(?!\d))/g, '&nbsp;');
	} else {
		out = out.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}
	return out;
}

function loadStock() {
	getJson(`https://tools.eurolandir.com/tools/pricefeed/?companycode=ru-gmkn&format=json`)
		.then(function (data) {
			let json = JSON.parse(data),
				MICEXValue = editValues(json['Norilsk Nickel (MOEX)']['Last'], 2),
				MICEXChange = editValues(json['Norilsk Nickel (MOEX)']['ChangePercent'], 2),
				LSEValue = editValues(json['Norilsk Nickel ADR (LSE)']['Last'], 2),
				LSEChange = editValues(json['Norilsk Nickel ADR (LSE)']['ChangePercent'], 2),
				allClasses = 'stockchart__delta--minus stockchart__delta--plus',
				newClassMICEX = 'stockchart__delta--minus',
				newClassLSE = 'stockchart__delta--minus',
				tooltipMICEX,
				tooltipLSE;

			if (getLang() == 'ru') {
				tooltipMICEX = `Данные предоставляются с задержкой как минимум на 15 минут по состоянию на ${json['Norilsk Nickel (MOEX)']['Date']} (GMT+03:00)`;
				tooltipLSE = `Данные предоставляются с задержкой как минимум на 15 минут по состоянию на ${json['Norilsk Nickel ADR (LSE)']['Date']} (GMT+03:00)`;
			} else {
				tooltipMICEX = `Market data delayed by 15 minutes as ${json['Norilsk Nickel (MOEX)']['Date']} (GMT+03:00)`;
				tooltipLSE = `Market data delayed by 15 minutes as ${json['Norilsk Nickel ADR (LSE)']['Date']} (GMT+03:00)`;
			}

			if (MICEXChange[0] !== '-') {
				MICEXChange = '+' + MICEXChange;
				newClassMICEX = 'stockchart__delta--plus';
			}

			if (LSEChange[0] !== '-') {
				LSEChange = '+' + LSEChange;
				newClassLSE = 'stockchart__delta--plus';
			}

			MICEXChange = MICEXChange + '%';
			LSEChange = LSEChange + '%';

			$.each($arStockCharts, function(index, el) {
				$(el).find('.js-stockchart__MICEX-value')
					.html(MICEXValue);
				$(el).find('.js-stockchart__LSE-value')
					.html(LSEValue);

				$(el).find('.js-stockchart__MICEX-change')
					.removeClass(allClasses)
					.addClass(newClassMICEX)
					.html(MICEXChange);
				$(el).find('.js-stockchart__LSE-change')
					.removeClass(allClasses)
					.addClass(newClassLSE)
					.html(LSEChange);

				$(el).find('.js-stockchart__MICEX-tooltip')
					.tooltipster('content', tooltipMICEX);
				$(el).find('.js-stockchart__LSE-tooltip')
					.tooltipster('content', tooltipLSE);
			});

			return json;
		})
		.catch(function (error) {
			console.error('Error: ' + error);
			showNotyfications(error, {type: 'error'});
		});
}

function loadMetalsStock() {
	getJson(`/local/templates/nornik/json/quotations.json`)
		.then(function (data) {
			let json = JSON.parse(data),
				listMetals = ["Copper", "Nickel", "Platinum", "Palladium"];

			for (var key in json) {
				let value = editValues(json[key]['Bid'], 0),
					change = editValues(json[key]['ChangePercent'], 2),
					allClasses = 'stockchart__delta--minus stockchart__delta--plus',
					newClass = 'stockchart__delta--minus',
					tooltip;

				let dayOld = moment(json[key]['Date'].replace(/\s.*/g, ''), 'YYYY-MM-DD');
				
				dayOld.subtract(1, 'days');
 				if (dayOld.format('E') == 7) {
 					dayOld.subtract(2, 'days');
 				}

				if (getLang() == 'ru') {
					tooltip = `Данные предоставлены по состоянию на ${dayOld.format('YYYY-MM-DD')}`;
				} else {
					tooltip = `Market data delayed as ${dayOld.format('YYYY-MM-DD')}`;
				}

				if (change[0] !== '-') {
					change = '+' + change;
					newClass = 'stockchart__delta--plus';
				}
				change = change + '%';

				$.each($arStockCharts, function(index, el) {
					$(el)
						.find(`[data-id="${key}"]`)
						.find(`.stockchart__value`)
						.eq(1)
						.find('span')
						.eq(0)
						.html(value);

					$(el)
						.find(`[data-id="${key}"]`)
						.find(`.stockchart__delta`)
						.removeClass(allClasses)
						.addClass(newClass)
						.html(change);

					$(el)
						.find(`[data-id="${key}"]`)
						.find('.tooltip')
						.tooltipster('content', tooltip);
				});
			}

			return json;
		})
		.catch(function (error) {
			console.error('Error: ' + error);
			showNotyfications(error, {type: 'error'});
		});
}

if ($arStockCharts.length) {
	loadStock();
	setInterval(loadStock, 300000);
	loadMetalsStock();
}