const cookie = require('cookie_js');
const platform = require('platform');
const throttle = require('throttle-debounce/throttle');
const verge = require('verge');

function illiquidsTourInit() {
	const $tourFilter = $(document).find('.popover__circle--filter');
	const $tourBtnShow = $(document).find('.js-chosen-rows');
	const $tourBtnDownload = $(document).find('.js-download-rows');
	const $chkboxTourAnchor = $(document).find('#tenders-table-illiquids')
		.find('tbody')
		.find('td')
		.first()
		.find('.checkbox');

	$('<div class="popover__circle popover__circle--chkbx"></div>').insertBefore('#tenders-table-illiquids');

	const $tourChkbx = $(document).find('.popover__circle--chkbx');

	// Instance the tour
	let tour = new Tour({
		storage: false,
		template: `<div class='popover tour'>
			<h3 class='popover-title'></h3>
			<div class='popover-counter'>
				<span class="popover-counter--current"></span>
				<span class="popover-counter--total"></span>
			</div>
			<div class='popover-content'></div>
			<div class="popover-navigation">
				<button class="btn btn-sm btn-default btn--bordered" data-role="end">Завершить тур</button>
				<button class="btn btn-sm btn-default btn--bordered" data-role="next">Далее</button>
			</div>
		</div>`,
		backdrop: true,
		steps: [{
			element: $tourFilter,
			placement: 'right',
			title: 'Отфильтруйте ',
			content: 'Воспользуйтесь фильтрами для уточнения выборки по позициям',
			onShown: function (tour) {
				$tourFilter.css('opacity', 1);
				$tourFilter
					.siblings('.tenders-filter__btn--filter')
					.addClass('highlighted');

				if ((platform.name == 'IE') || (platform.name == 'Microsoft Edge')) {
					$(document).find('.tour-backdrop').css('border', '1px solid #000');
				}

				$(document).find('.popover-counter--total').text('/ ' + tour._options.steps.length);
				$(document).find('.popover-counter--current').text(tour.getCurrentStep() + 1);
			},
			onNext: function (tour) {
				fixPosition();
			}
		},
		{
			element: $tourChkbx,
			placement: 'right',
			title: 'Выберите',
			content: 'Выберите позиции, которые вас заинтересовали',
			onShown: function (tour) {
				$tourChkbx.css('opacity', 1);
				$chkboxTourAnchor
					.find('.checkbox__label')
					.addClass('highlighted');

				if ((platform.name == 'IE') || (platform.name == 'Microsoft Edge')) {
					$(document).find('.tour-backdrop').css('border', '1px solid #000');
				}

				$(document).find('.popover-counter--total').text('/ ' + tour._options.steps.length);
				$(document).find('.popover-counter--current').text(tour.getCurrentStep() + 1);

			}
		},
		{
			element: $tourBtnShow,
			placement: 'left',
			title: 'Посмотрите',
			content: 'Посмотрите все выбранные вами позиции',
			backdropPadding: {
				top: 10,
				right: 14,
				bottom: 14,
				left: 10
			},
			onShown: function (tour) {
				$tourBtnShow
					.addClass('highlighted');

				if ((platform.name == 'IE') || (platform.name == 'Microsoft Edge')) {
					$(document).find('.tour-backdrop').css('border', '1px solid #000');
				}

				$(document).find('.popover-counter--total').text('/ ' + tour._options.steps.length);
				$(document).find('.popover-counter--current').text(tour.getCurrentStep() + 1);
			}
		},
		{
			element: $tourBtnDownload,
			placement: 'left',
			title: 'Скачайте',
			content: 'Скачайте Excel-файл с выбранными позициями. Количество файлов совпадает с количеством выбранных вами компаний Группы (РОКСов)',
			backdropPadding: {
				top: 10,
				right: 14,
				bottom: 14,
				left: 10
			},
			onShown: function (tour) {
				$tourBtnDownload
					.addClass('highlighted');

				if ((platform.name == 'IE') || (platform.name == 'Microsoft Edge')) {
					$(document).find('.tour-backdrop').css('border', '1px solid #000');
				}

				$(document).find('.popover-counter--total').text('/ ' + tour._options.steps.length);
				$(document).find('.popover-counter--current').text(tour.getCurrentStep() + 1);
			}
		}],
		onStart: function (tour) {
			$(document).find('#tenders-table-illiquids').addClass('pointer-events--no');
			$('body').addClass('overflow--no');
			if ((platform.name == 'IE') || (platform.name == 'Microsoft Edge')) {
				$(document).find('.tour-backdrop').css('border', '1px solid #000');
			}
		},
		onNext: function (tour) {
			$tourFilter.css('opacity', 0);
			$tourChkbx.css('opacity', 0);
			$(document).find('.highlighted').removeClass('highlighted');
			$(document).find('.tour-backdrop').css('border', 'none');
		},
		onHide: function (tour) {
			$tourFilter.css('opacity', 0);
			$tourChkbx.css('opacity', 0);
			$(document).find('.highlighted').removeClass('highlighted');
		},
		onEnd: function (tour) {
			$(document).find('.popover__circle--chkbx').remove();
			$(document).find('#tenders-table-illiquids').removeClass('pointer-events--no');
			$(document).find('.highlighted').removeClass('highlighted');
			$('body').removeClass('overflow--no');

			cookie.set('TOUR_COMPLETED', '1');
		}
	});

	if (tour.ended()) {
		tour.restart();
	} else {
		tour.init();
		tour.start();
	}


	$(window).resize(
		throttle(100, function () {
			// fixPosition();

			let currentWidth = verge.viewportW(),
				mobile = false;
			if (currentWidth <= 768) {
				mobile = true;
			}

			if (!tour.ended() && mobile) {
				tour.end();
			}
		})
	);
}

export {illiquidsTourInit};

function fixPosition() {
	const $chkboxTourAnchor = $(document).find('#tenders-table-illiquids')
		.find('tbody')
		.find('td')
		.first()
		.find('.checkbox');
	const $tourChkbx = $(document).find('.popover__circle--chkbx');

	let anchorTop = $chkboxTourAnchor.offset().top;

	$tourChkbx.css('top', Math.round(anchorTop - $tourChkbx.parent().offset().top - 22));
}

$(window).on('scroll', function () {
	$(document).find('.tour-backdrop').css('border', 'none');
});

