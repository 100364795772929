const $arListCollapsed = $('.js-ul--collapse');
const expandClass = 'is-expand';

function showLis($rows, button) {
	//показыаем все строки
	$rows.show();
	//добавляем класс раскрытия
	button.addClass(expandClass);
	//меняем текст на тогле
	if (getLang() == 'ru') {
		button
			.find('button')
			.html('Свернуть');
	} else if (getLang() == 'en') {
		button
			.find('button')
			.html('Collapse list');
	}
}

function hideLis($lis, visibleLis, button, user = true) {
	//скрываем все строки, кроме минимального количества видимых
	$lis
		.not($lis.slice(0, visibleLis))
		.hide();
	//удаляем классы раскрытия
	button.removeClass(expandClass);
	//меняем текст на тогле
	if (getLang() == 'ru') {
		button
			.find('button')
			.html('Показать весь список');
	} else if (getLang() == 'en') {
		button
			.find('button')
			.html('Show the full list');
	}
	//скролим к таблице
	if (user) {
		$(window).scrollTop(button.closest('ul').offset().top);
	}
}

function toggleVisibleLis($lis, visibleLis, button) {
	//Если у обработчика есть класс раскрытия, т.е. блок нужно сворачивать
	if (button.hasClass(expandClass)) {
		hideLis($lis, visibleLis, button);
	} else {
		showLis($lis, button);
	}
	button
		.find('button')
		.blur();
}

function addToggleVisibleLiButton($list, visibleLis) {
	// debugger;
	let $toggle = $list.find('.js-toggle-visible-li'); //Пытаемся найти элемент с созданной ранее кнопкой
	let $lis = $list.find('li'); //Получаем все строчки в tbody
	//Количество найденных строк больше, чем минимально показываемое количество?
	if ($lis.length > visibleLis) {
		//Была ли добавлена ранее кнопка?
		if ($toggle.length) {
			return false;
		} else {
			//Создаем элемент кнопки
			let button = $('<li><button class="btn one-whole btn--bordered ul__toggle-visible-li-trigger js-toggle-visible-li"/></li>');
			//Прописываем название кнопки
			if (getLang() == 'ru') {
				button
					.find('button')
					.html('Показать весь список');
			} else if (getLang() == 'en') {
				button
					.find('button')
					.html('Show the full list');
			}
			//Добавляем кнопку в элеменет таблицы
			$list
				.append(button);
			//Создаем обработчик события клик
			button.on('click', () => toggleVisibleLis($lis, visibleLis, button));
			//скрываем строки посли подгрузки кнопки
			hideLis($lis, visibleLis, button, false);
		}
	}
}

$(document).ready(function () {
	if ($arListCollapsed.length) {
		$.each($arListCollapsed, function(i, el) {
			const $this = $(el);
			let visibleLiCount = $this.attr('data-visible-li');
			if (typeof visibleLiCount != 'undefined') {
				addToggleVisibleLiButton($this, visibleLiCount);
			}
		});
	}
})