<template>
    <div class="filter-timeline">
        <ul class="filter-timeline__list--years filter-timeline__list--open">
            <li class="filter-timeline__item filter-timeline__item--all">
                <a
                    @click.prevent="changeYear('all')"
                    :class="{
                        'filter-timeline__link--active': activeYear == 'all'
                    }"
                    class="filter-timeline__link filter-timeline__link--all"
                    href="javascript: void(0);"
                >
                    <span class="filter-timeline__text">{{ text.all }}</span>
                </a>
            </li>
            <li
                v-for="item in years"
                class="filter-timeline__item"
            >
                <a
                    @click.prevent="changeYear(item.id)"
                    :class="{
                        'disabled': item.disabled,
                        'filter-timeline__link--active': item.id == activeYear
                    }"
                    class="filter-timeline__link"
                    href="javascript: void(0);"
                >
                    <span class="filter-timeline__text">{{ item.id }}</span>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'media-years',
    props: {
    	years: {
    		type: Array,
    		default: () => []
    	},
    	defaultYear: {
    		type: String,
    		default: ''
    	}
    },
    data() {
        return {
            activeYear: this.defaultYear,
            currentYear: this.years[0],
            text: {
                all: this.$parent.lang == 'ru' ? 'Все' : 'All'
            }
        }
    },
    methods: {
        changeYear(year) {
            this.activeYear = year;
            this.$parent.params.page = 1;
            this.$parent.params.year = year;
            this.$parent.$emit('changeFilters');
        }
    }
};
</script>