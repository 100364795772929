const $listStepsSlider = $('.js-list-steps-slider');

if ($listStepsSlider.length) {
	let swiperSteps = new Swiper('.js-list-steps-slider', {
						speed: 400,
						mousewheelControl: true,
						simulateTouch: false,
						mousewheelControl: false,
						loop: true,
						autoHeight: true,
						nextButton: '.list-steps__slider__nav--right',
						prevButton: '.list-steps__slider__nav--left'
					});
}

function checkCasesHash() {
	if (window.location.hash.length > 0 && !(window.location.hash == '#!')) {
		let $item = $(`.cases-totals-item[data-hash="${window.location.hash}"]`);
		$item.velocity('scroll', {
			duration: 500,
			offset: -40,
			easing: 'ease-in-out'
		});
	}
}

if ($('.cases-totals-nav').length) {
	checkCasesHash();

	$(document).on('click', '.aside__lang', function (e) {
		e.preventDefault();
		window.location.href = $(this).attr('href') + getHash().href;
	});
}

$(window).on('hashchange', function () {
	if ($('.cases-totals-nav').length) {
		checkCasesHash();
	}
});