const debounce = require('throttle-debounce/debounce');

function loadContactsPage(params) {
	$('.js-contacts-page__row').html('<div class="contacts__preloader"><img src="/images/svg/loader.svg"></div>');
	$.post('/ajax/contacts-page.php', params, function (data) {
		$('.js-contacts-page__row').html(data);
	});
}

$(document).on('click', '.js-contacts-page .js-filter__link', debounce(500, function (e) {
	let $this = $(this),
		$container = $this.closest('.filter__group'),
		$containerSiblings = $container.siblings('.filter__group');

	if ($this.hasClass('filter-item__filter-link--disabled')) {
		return false;
	}

	if ($this.hasClass('js-filter__all')) {
		$container
			.find('.filter-item__filter-link:not(.js-filter__all):not(.js-filter__clear)')
			.addClass('filter-item__filter-link--active');
		$this.addClass('filter-item__filter-link--disabled');
		$container
			.find('.js-filter__clear')
			.removeClass('filter-item__filter-link--disabled');
	} else if ($this.hasClass('js-filter__clear')) {
		if ($('.js-contacts-page--assets').length) {
			$container
				.find('.filter-item__filter-link:not(.js-filter__all):not(.js-filter__clear)')
				.removeClass('filter-item__filter-link--active');
			$this.addClass('filter-item__filter-link--disabled');

			let paths = [];
			$.each($container.find('.filter-item__filter-link'), function (index, el) {
				paths.push(`/${getLang()}/${$(el).closest('.filter__container').attr('data-filter-container')}/${$(el).attr('data-filter-id')}.php`);
			});
			loadContactsPage(`paths=${paths}`);
			return false;
		} else {
			$('.js-contacts-page').find('.js-filter__all').eq(0).trigger('click');
			return false;
		}
	} else {
		$(this).toggleClass('filter-item__filter-link--active');
		checkAllActiveFilter($container);
	}

	$containerSiblings
		.find('.filter-item__filter-link')
		.removeClass('filter-item__filter-link--active');

	checkAllActiveFilter($containerSiblings);

	if (!$container.find('.filter-item__filter-link--active').length) {
		let paths = [];

		if (!$('.js-contacts-page--assets').length) {
			$('.filter__group').eq(0)
				.find('.filter-item__filter-link:not(.js-filter__all)')
				.addClass('filter-item__filter-link--active');

			$('.filter__group').eq(0)
				.find('.js-filter__all')
				.addClass('filter-item__filter-link--disabled');

			$.each($('.filter__group').eq(0).find('.filter-item__filter-link'), function (index, el) {
				paths.push(`/${getLang()}/${$(el).closest('.filter__container').attr('data-filter-container')}/${$(el).attr('data-filter-id')}.php`);
			});
		} else {
			$container
				.find('.js-filter__all')
				.removeClass('filter-item__filter-link--active')
				.addClass('filter-item__filter-link--disabled');

			$.each($container.find('.filter-item__filter-link'), function (index, el) {
				paths.push(`/${getLang()}/${$(el).closest('.filter__container').attr('data-filter-container')}/${$(el).attr('data-filter-id')}.php`);
			});
		}

		loadContactsPage(`paths=${paths}`);
	} else {
		let $arrActiveEl = $container.find('.filter-item__filter-link--active'),
			paths = [];

		$.each($arrActiveEl, function (index, el) {
			paths.push(`/${getLang()}/${$(el).closest('.filter__container').attr('data-filter-container')}/${$(el).attr('data-filter-id')}.php`);
		});

		loadContactsPage(`paths=${paths}`);
	}
}));


if ($('.js-contacts-page:not(.js-contacts-page--assets)').length) {
	loadContactsPage(`paths=/${getLang()}/main/main.php,/${getLang()}/main/smi.php,/${getLang()}/main/investors.php,/${getLang()}/main/marketing.php,/${getLang()}/main/anticorruption.php,/${getLang()}/main/insiders.php,/${getLang()}/main/subsidiaries.php`);
}

if ($('.js-contacts-page--assets').length) {
	loadContactsPage(`paths=/${getLang()}/directions/non-core-assets.php,/${getLang()}/directions/illiquid.php`);
}
