const verge = require('verge');
const throttle = require('throttle-debounce/throttle');

function animateStrategy1Left() {
	if ($('html').hasClass('mdr-no-smil') && !$('#strategy-1-left').hasClass('is-animate')) {
		$('#strategy-1-left').find('#strategy-1-left__list')
			.velocity('stop')
			.velocity({opacity: 1}, {duration: 400});
		$('#strategy-1-left').find('#strategy-1-left__line')
			.velocity('stop')
			.velocity({opacity: 1, translateX: [0, '-20']}, {duration: 400, delay: 200});
		$('#strategy-1-left').find('#strategy-1-left__text')
			.velocity('stop')
			.velocity({opacity: 1}, {duration: 400, delay: 800});
		$('#strategy-1-right')
			.velocity('stop')
			.velocity({opacity: 1}, {
				duration: 400,
				delay: 800,
				complete: function () {
					$('#strategy-1-left').addClass('is-animate');
					$('#strategy-1-right').addClass('is-animate');
				}
			});
	} else {
		$('#strategy-1-left').addClass('is-animate');
		$('#strategy-1-right').addClass('is-animate');
	}
}

// INIT
if (verge.inViewport($('#strategy-1-left'), '10%')) {
	animateStrategy1Left();
}

$(document).on('touchmove scroll mousewheel DOMMouseScroll', throttle(250, function () {
	if (verge.inViewport($('#strategy-1-left'), '10%')) {
		animateStrategy1Left();
	}
}));

var contentSections = $('section.strategy__row'),
	navigationItems = $('#strategy-pagination a');

function updateNavigation() {
	contentSections.each(function (i, el) {
		let $this = $(el);
		var activeSection = $('#strategy-pagination a[href="#' + $this.attr('data-id') + '"]').data('number') - 1;
		if (($this.offset().top - $(window).height() / 2 < $(window).scrollTop()) && ($this.offset().top + $this.height() - $(window).height() / 2 > $(window).scrollTop())) {
			navigationItems.eq(activeSection).addClass('is-selected');
		} else {
			navigationItems.eq(activeSection).removeClass('is-selected');
		}
	});
}

function smoothScroll(target) {
	$('body,html').animate(
		{scrollTop: target.offset().top},
		600
	);
	window.location.hash = '!';
}


function checkStrategyHash() {
	if (window.location.hash.length > 0 && !(window.location.hash == '#!')) {
		var hash = window.location.hash.substr(1);
		if ($('[data-id=' + hash + ']').length > 0) {
			smoothScroll($(`[data-id="${hash}"]`));
		}
	}
}

if ($('.strategy').length) {
	updateNavigation();
	checkStrategyHash();
}

$(window).on('scroll', function () {
	if ($('.strategy').length) {
		updateNavigation();
	}
});

$(window).on('hashchange', function () {
	if ($('.strategy').length) {
		checkStrategyHash();
	}
});


//
// STRATEGY-1 RIGHT
//
//
function startAnimateMarkers($el) {
	let $markers = $(`.map-strategy__marker[data-id="${$el.data('id')}"]`);
	if (!$el.hasClass('is-hover')) {
		if ($('html').hasClass('mdr-no-smil')) {
			$markers
				.velocity({translateY: ['-8px', 0]}, {
					duration: 400,
					loop: true
				});
		}
		$el.addClass('is-hover');
		$markers.addClass('is-hover');
	}
}

function endAnimateMarkers($el) {
	let $markers = $(`.map-strategy__marker[data-id="${$el.data('id')}"]`);
	if ($el.hasClass('is-hover')) {
		if ($('html').hasClass('mdr-no-smil') && !$el.hasClass('is-active')) {
			$markers
				.velocity('stop', true)
				.velocity({translateY: 0}, {
					duration: 0
				})
				.removeClass('velocity-animating')
				.velocity('finish');
		}
		$el.removeClass('is-hover');
		$markers.removeClass('is-hover');
	}
}

function showContentMarker($el) {
	let $markers = $(`.map-strategy__marker[data-id="${$el.data('id')}"]`),
		$content = $(`.strategy-1-right__content[data-id="${$el.data('id')}"]`);

	if (!$markers.hasClass('is-active')) {
		if ($('html').hasClass('mdr-no-smil')) {
			$markers
				.velocity({translateY: ['-8px', 0]}, {
					duration: 400,
					loop: true
				});
		}
	}
	$el.addClass('is-active');
	$markers.addClass('is-active');
	$content.addClass('is-active');
}

function hideContentMarkers() {
	let $markersActive = $(`.map-strategy__marker.is-active`),
		$contentActive = $(`.strategy-1-right__content.is-active`),
		$legendActive = $(`.map-strategy__legend.is-active`);

	if ($('html').hasClass('mdr-no-smil') && !$markersActive.hasClass('is-hover')) {
		$markersActive
			.velocity('stop', true)
			.velocity({translateY: 0}, {
				duration: 0
			})
			.removeClass('velocity-animating')
			.velocity('finish');
	}
	$markersActive.removeClass('is-active');
	$contentActive.removeClass('is-active');
	$legendActive.removeClass('is-active');
}

$('.map-strategy__legend').on('mouseenter', function () {
	startAnimateMarkers($(this));
});

$('.map-strategy__legend').on('mouseleave', function () {
	endAnimateMarkers($(this));
});

$('.map-strategy__legend').on('click', function () {
	let $legend = $(this);
	if (!$legend.hasClass('is-active')) {
		hideContentMarkers();
		showContentMarker($legend);
	} else {
		hideContentMarkers();
	}
});

$('.map-strategy__marker').on('click', function () {
	let $legend = $(`.map-strategy__legend[data-id="${$(this).data('id')}"]`);
	if (!$legend.hasClass('is-active')) {
		hideContentMarkers();
		showContentMarker($legend);
	} else {
		hideContentMarkers();
	}
});

$('.strategy-1-right__content-close').on('click', function (e) {
	e.preventDefault();
	hideContentMarkers();
});