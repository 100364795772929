function initVideo() {
    const video = Array.from(document.querySelectorAll('.js-video'))

    video.forEach(item => {
        const cover = item.querySelector('.video-conatiner__cover')
        const video = item.querySelector('.video-conatiner__video')

        cover.addEventListener('click', e => {
            item.classList.add('is-active')
            video.play()
        })
    });
}

initVideo()
