'use strict';

document.addEventListener('DOMContentLoaded', () => {
    let businessMap = document.querySelector('.business-maps'),
        exists = typeof(businessMap) != 'undefined' && businessMap != null;

    if (exists) getMarkersData(businessMap);
});

class BusinessMaps {

    constructor(data, container) {
        this.data = data;
        this.container = container;
        
        this.tabs = this.container.querySelectorAll('.business-maps__tab');
        this.views = this.container.querySelectorAll('.business-maps__view');
        this.markers = this.container.querySelectorAll('.business-maps__object');

        this.popup = {
            itself: this.container.querySelector('.business-maps__popup'),
            close: this.container.querySelector('.business-maps__popup-close'),
            header: this.container.querySelector('.business-maps__popup-header span'),
            imageWrap: this.container.querySelector('.business-maps__popup-image'),
            image: this.container.querySelector('.business-maps__popup-image img'),
            body: this.container.querySelector('.business-maps__popup-body'),
        };
    }

    init() {
        this.mapEvents();
        this.toggleMaps();
        this.popupEvents();
        this.setClickable();
    }

    setClickable() {
        for (let i = 0; i < this.data.length; i++) {
            if (!this.data[i].clickable) this.markers[i].classList.add('disabled');
        };
    }

    toggleMaps() {
        let loop = (tab, i) => {
            tab.addEventListener('click', () => {
                this.toggleClass(this.tabs, i, true);
                this.toggleClass(this.views, i, true);
            });
        };

        for (let i = 0; i < this.tabs.length; i++) {
            loop(this.tabs[i], i);
        };
    }

    mapEvents() {
        let loop = (marker, i) => {
            marker.addEventListener('click', e => {
                e.stopPropagation();

                let markerID = marker.getAttribute('data-id');

                this.toggleClass(this.markers, i, true);
                this.clearPopupData();
                this.setPopupData(markerID);
                this.showPopup();
            });
        };

        for (let i = 0; i < this.markers.length; i++) {
            loop(this.markers[i], i);
        };
    }

    popupEvents() {
        this.popup.close.addEventListener('click', () => {
            this.toggleClass(this.markers, 0);
            this.hidePopup();
        });

        document.addEventListener('click', e => {
            let outsideClick = !this.popup.itself.contains(e.target),
                active = this.popup.itself.classList.contains('active');

            if (outsideClick && active) {
                this.toggleClass(this.markers, 0);
                this.hidePopup();
            };
        });
    }

    showPopup() {
        this.popup.itself.classList.add('active');
    }

    hidePopup() {
        this.popup.itself.classList.remove('active');
    }

    clearPopupData() {
        while (this.popup.body.firstChild) {
            this.popup.body.removeChild(this.popup.body.firstChild);
        };
    }

    setPopupData(id) {
        let currentData = this.data[id];

        this.popup.header.innerHTML = currentData.title;

        if (currentData.image.show == true) {
            this.popup.image.setAttribute('src', currentData.image.src);
            this.popup.imageWrap.style.display = 'block';
        } else {
            this.popup.imageWrap.style.display = 'none';
        };

        if (currentData.desc.length > 0) {
            for (let i = 0; i < currentData.desc.length; i++) {
                this.appendRow(currentData.desc[i]);
            };
        };
    }

    appendRow(data) {
        let row = document.createElement('div'),
            text = document.createElement('div'),
            title = document.createElement('div');

        row.className = 'business-maps__popup-row';
        text.className = 'business-maps__popup-text';
        title.className = 'business-maps__popup-title';

        if (data.name != undefined) {
            title.innerHTML = data.name;
            row.appendChild(title);
        };

        if (data.value != undefined) {
            text.innerHTML = data.value;
            row.appendChild(text);
        };

        this.popup.body.appendChild(row);
    }

    toggleClass(el, index, type = false) {
        for (let i = 0; i < el.length; i++) {
            el[i].classList.remove('current');
        };

        type ? el[index].classList.add('current') : false;
    }
};

let getMarkersData = container => {
    let http = new XMLHttpRequest(),
        url = '../../business/maps-objects.json';

    http.open('GET', url, true);

    http.onreadystatechange = function() {
        if (http.readyState == 4 && http.status == 200) {
            try {
                var data = JSON.parse(http.responseText);
            } catch (err) {
                console.log(err.message + ' in ' + http.responseText);
                return;
            };

            let markersData = new BusinessMaps(data, container).init();
        };
    };

    http.send();
};