module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (obj.page.total == "0") { ;
__p += '\n\n	<div class="col-default">\n	';
 if ($('html').attr('lang') == 'ru') { ;
__p += '\n		<h2>Новостей за выбранный временной диапазон с данными параметрами фильтра не найдено</h2>\n	';
 } else if ($('html').attr('lang') == 'en') { ;
__p += '\n		<h2>No news for the selected time range of the data filter settings</h2>\n	';
 } ;
__p += '\n	</div>\n\n';
 } else { ;
__p += '\n\n	';
 for(var i=0; i < obj.items.length; i++) { ;
__p += '\n		';
 if (obj.items[i].priority) { ;
__p += '\n		<div class="col-default-4 col-tablet-6 col-mobile-hide">\n			<article class="news-item news-item--priority" id="' +
((__t = ( obj.items[i].id )) == null ? '' : __t) +
'">\n				<div class="news-item__container">\n					<a href="' +
((__t = ( obj.items[i].link.href )) == null ? '' : __t) +
'" class="news-item__img">\n						<img src="' +
((__t = ( obj.items[i].imgSrc )) == null ? '' : __t) +
'" class="news-item__img-link">\n						<span class="news-item__type news-item__type--' +
((__t = ( obj.items[i].type.id )) == null ? '' : __t) +
'">' +
((__t = ( obj.items[i].type.text )) == null ? '' : __t) +
'</span>\n						<div class="news-item__footer">\n							<span class="news-item__title">' +
((__t = ( obj.items[i].title )) == null ? '' : __t) +
'</span>\n							<time pubtime datetime="' +
((__t = ( obj.items[i].date.number )) == null ? '' : __t) +
'" class="news-item__date"><i>' +
((__t = ( obj.items[i].date.text )) == null ? '' : __t) +
'</i></time>\n						</div>\n					</a>\n				</div>\n			</article>\n		</div>\n		';
 } else { ;
__p += '\n		<div class="col-default-12">\n			<article class="news-item news-item--list news-item--type_' +
((__t = ( obj.items[i].type.id )) == null ? '' : __t) +
' news-item--icon_' +
((__t = ( obj.items[i].source )) == null ? '' : __t) +
'" id="' +
((__t = ( obj.items[i].id )) == null ? '' : __t) +
'">\n				<div class="news-item__container">\n					<div class="news-item__header">\n						<time pubtime datetime="' +
((__t = ( obj.items[i].date.number )) == null ? '' : __t) +
'" class="news-item__date">' +
((__t = ( obj.items[i].date.text )) == null ? '' : __t) +
'</time>\n						<span class="news-item__type news-item__type--' +
((__t = ( obj.items[i].type.id )) == null ? '' : __t) +
'">' +
((__t = ( obj.items[i].type.text )) == null ? '' : __t) +
'</span>\n					</div>\n					<a href="' +
((__t = ( obj.items[i].link.href )) == null ? '' : __t) +
'" class="news-item__title">\n						<span>' +
((__t = ( obj.items[i].title )) == null ? '' : __t) +
'</span>\n					</a>\n				</div>\n			</article>\n		</div>\n\n		';
 } ;
__p += '\n	';
 } ;
__p += '\n';
 } ;
__p += '\n';

}
return __p
}