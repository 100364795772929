import Vue from 'vue';
import Media from './media.vue';
import MediaItems from './media-items.vue';
import MediaAlbums from './media-albums.vue';
import MediaFilter from './media-filter.vue';

const mediaRoot = document.getElementById('media-app');
const exists = typeof(mediaRoot) != 'undefined' && mediaRoot != null;

if (exists) MediaApp();

function MediaApp() {
    new Vue({
        el: '#media-app',
        components: {
        	Media,
        	MediaItems,
        	MediaAlbums,
        	MediaFilter
        }
    });
};