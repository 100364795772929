export let ratingsLegendY = {
	"9.5": ["Baa1 +", "BBB+ +"],
	"9": ["Baa1 S", "BBB+ S"],
	"8.5": ["Baa1 -", "BBB+ -"],
	"8": ["Baa2 +", "BBB +"],
	"7.5": ["Baa2 S", "BBB S"],
	"7": ["Baa2 -", "BBB -"],
	"6.5": ["Baa3 +", "BBB- +"],
	"6": ["Baa3 S", "BBB- S"],
	"5.5": ["Baa3 -", "BBB- -"],
	"5": ["Ba1 +", "BB+ +"],
	"4.5": ["Ba1 S", "BB+ S"],
	"4": ["Ba1 -", "BB+ -"],
	"3.5": ["Ba2 +", "BB +"],
	"3": ["Ba2 S", "BB S"],
	"2.5": ["Ba2 -", "BB -"]
};

export let ratingsLegendX = {
	"1": localize({
		ru: `Январь`,
		en: 'January'
	}),
	"2": localize({
		ru: `Февраль`,
		en: 'February'
	}),
	"3": localize({
		ru: `Март`,
		en: 'March'
	}),
	"4": localize({
		ru: `Апрель`,
		en: 'April'
	}),
	"5": localize({
		ru: `Май`,
		en: 'May'
	}),
	"6": localize({
		ru: `Июнь`,
		en: 'June'
	}),
	"7": localize({
		ru: `Июль`,
		en: 'July'
	}),
	"8": localize({
		ru: `Август`,
		en: 'August'
	}),
	"9": localize({
		ru: `Сентябрь`,
		en: 'September'
	}),
	"10": localize({
		ru: `Октябрь`,
		en: 'October'
	}),
	"11": localize({
		ru: `Ноябрь`,
		en: 'November'
	}),
	"12": localize({
		ru: `Декабрь`,
		en: 'December'
	})
};
