require('intl-tel-input/build/js/utils.js');
let stateFocused = false,
	$fieldPhone = $(document).find($('.js-mask-phone')),
	maskOut,
	dialCodeStr;

$fieldPhone
	.intlTelInput({
		initialCountry: 'auto',
		autoHideDialCode: false,
		preferredCountries: '',
		autoPlaceholder: 'aggressive',
		formatOnDisplay: false,
		separateDialCode: true,
		geoIpLookup: function (callback) {
			$.get('https://ipinfo.io/', function () {}, 'jsonp').always(function (resp) {
				var countryCode = (resp && resp.country) ? resp.country : '';
				callback(countryCode);
			});
		}
	});

$fieldPhone.on('countrychange', function (e, countryData) {
	$fieldPhone
		.val('')
		.mask($fieldPhone.attr('placeholder').replace(/\d/gi, '9'), {
			placeholder: $fieldPhone.attr('placeholder').replace(/\d/gi, '_'),
			autoclear: false
		});
});

$fieldPhone.on('keyup blur', function (e) {
	let error = $fieldPhone.intlTelInput('getValidationError');
	let isValid = $fieldPhone.intlTelInput('isValidNumber');
});

$.validator.addMethod('tel', function (value, element) {
	if (value.length) {
		return $fieldPhone.intlTelInput('isValidNumber');
	} else {
		return true;
	}
});
