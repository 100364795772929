const cookie = require('cookie_js');
let cookieValue = cookie('cookies_policy');

$(function () {
	if (cookieValue !== 'true') {
		$('.cookie').show();
	}

	$(document).on('click', '.js-cookie__btn', function (e) {
		cookie.set({
			cookies_policy: true
		}, {
			domain: '.' + location.host,
			path: '/',
			expires: 365
		});
	});

	$(document).on('click', function (e) {
		if ($('.cookie').is(':visible')) {
			$('.cookie').hide();
		}
	});
});
