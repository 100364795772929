module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


	let titleShare = localize({
			ru: 'Поделиться',
			en: 'Share'
		}),
		titleDownload = localize({
			ru: 'Скачать',
			en: 'Download'
		}),
		titleZoom = localize({
			ru: 'Увеличить',
			en: 'Zoom'
		}),
		textNotFound = localize({
			ru: 'Медиаматериалов за выбранный временной диапазон с данными параметрами фильтра не найдено',
			en: 'No media for the selected time range of the data filter settings'
		}),
		titleAlbum = localize({
			ru: 'Альбом:',
			en: 'Album:'
		}),
		titleTopic = localize({
			ru: 'Тема:',
			en: 'Topic:'
		}),
		titleType = localize({
			ru: 'Тип:',
			en: 'Type:'
		});
;
__p += '\n';
 if (data.page.total == "0") { ;
__p += '\n\n	<div class="col-default">\n		<h2>' +
((__t = (textNotFound )) == null ? '' : __t) +
'</h2>\n	</div>\n\n';
 } else { ;
__p += '\n\n	';

		for(var i = 0; i < data.sections.length ; i++) {
	;
__p += '\n	<div class="col-default-4 col-tablet-6 col-mobile-12" id=\'js-media-gallery-' +
((__t = (data.sections[i].code)) == null ? '' : __t) +
'\'>\n			';

				var sectionItems = data.sections[i].items;
				for(var j = 0; j < sectionItems.length; j++) {
					let download, link, name, href, hrefHtml, platformVideo, type;

					// console.log(sectionItems[j]);
					// debugger;
					if (sectionItems[j].propVideoLink == '' || sectionItems[j].propVideoLink == null) {
						href = 'href="'+ sectionItems[j].img.highImg.src + '"'
					} else {
						platformVideo = sectionItems[j].propVideoLink.match(/(http:\/\/|https:\/\/|)(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/g);
						if (platformVideo !== null) {
							href = 'href="'+ sectionItems[j].propVideoLink + '"'
						} else {
							href = 'data-html="#video-' + data.sections[i].code + '-n-' + j + '"';
							hrefHtml = "<div style=\"display:none;\" id=\"video-" + data.sections[i].code + "-n-" + j + "\"><video class=\"lg-video-object lg-html5 video-js vjs-default-skin\" controls preload=\"none\"><source src=\"" + sectionItems[j].propVideoLink + "\" type=\"video/mp4\">Your browser does not support HTML5 video.</video></div>";
						}
					}

					if (sectionItems[j].propDoc == '' || sectionItems[j].propDoc == null) {
						if (sectionItems[j].propType.xmlID == 'foto') {
							download = sectionItems[j].img.originalImg.src
							download = (sectionItems[j].img.originalImg.src !== '') ? sectionItems[j].img.originalImg.src : sectionItems[j].img.highImg.src
						}
					} else {
						download = sectionItems[j].propDoc
					}

					if (!(sectionItems[j].propExtLink == '' || sectionItems[j].propExtLink == null)) {
						link = sectionItems[j].propExtLink
					}

					if (sectionItems[j].propName == '' || sectionItems[j].propName == null) {
						name = sectionItems[j].name
					} else {
						name = sectionItems[j].propName
					}

					if (sectionItems[j].hasOwnProperty("propType")) {
						if (sectionItems[j].propType.xmlID == 'foto') {
							type = localize({
								ru: 'Фото',
								en: 'Photo'
							})
						}
						if (sectionItems[j].propType.xmlID == 'identity') {
							type = localize({
								ru: 'Айдентика',
								en: 'Identity'
							})
						}
						if (sectionItems[j].propType.xmlID == 'video') {
							type = localize({
								ru: 'Видео',
								en: 'Video'
							})
						}
						if (sectionItems[j].propType.xmlID == 'interview') {
							type = localize({
								ru: 'Интервью',
								en: 'Interview'
							})
						}
						if (sectionItems[j].propType.xmlID == 'presentation') {
							type = localize({
								ru: 'Презентация',
								en: 'Presentation'
							})
						}
						if (sectionItems[j].propType.xmlID == 'press-kit') {
							type = localize({
								ru: 'Пресс-кит',
								en: 'Press-kit'
							})
						}
					}
			;
__p += '\n			<figure class="media__item media__item--album figure figure--gradient u-margin--vetical" ' +
((__t = ( j > 0 ? 'style="display: none"' : '' )) == null ? '' : __t) +
'>\n				<div class="figure__content">\n					<img class="figure__image" src="' +
((__t = (sectionItems[j]['img']['mediumImg']['src'] )) == null ? '' : __t) +
'" data-object-fit="cover">\n				</div>\n				<figcaption class="figure__caption media__caption">\n					<span class="figure__text">' +
((__t = (data.sections[i].name )) == null ? '' : __t) +
'</span>\n				</figcaption>\n				<div class="media__add">\n					<a\n						' +
((__t = (href )) == null ? '' : __t) +
'\n					';
 if (download !== undefined) { ;
__p += '\n						data-download-url="' +
((__t = (download )) == null ? '' : __t) +
'"\n					';
 } ;
__p += '\n						data-thumb="' +
((__t = (sectionItems[j].img.smallImg.src )) == null ? '' : __t) +
'"\n						data-sub-html=\'\n							<div class="media__header">\n								<time><i>' +
((__t = (sectionItems[j].dateFormatted )) == null ? '' : __t) +
'</i></time>\n								<span class="media__title serif-bottom-blue">' +
((__t = (name )) == null ? '' : __t) +
'</span>\n							</div>\n							<div class="media__body">\n								<p>' +
((__t = (sectionItems[j].text )) == null ? '' : __t) +
'</p>\n							</div>\n							<div class="media__footer">\n							';
 if (sectionItems[j].hasOwnProperty("albums")) { ;
__p += '\n								<div class="media__footer-line">\n									<b class="media__footer-title">' +
((__t = (titleAlbum)) == null ? '' : __t) +
'</b>\n								';
 $.each(sectionItems[j].albums, function(key, value) { ;
__p += '\n									<a href="#" data-section="' +
((__t = (value.code )) == null ? '' : __t) +
'" class="link js-link-album"><span>' +
((__t = (value.value )) == null ? '' : __t) +
'</span></a>\n								';
 }); ;
__p += '\n								</div>\n							';
 } ;
__p += '\n							';
 if (sectionItems[j].hasOwnProperty("propSubject") && sectionItems[j].propSubject.length) { ;
__p += '\n								<div class="media__footer-line">\n									<b class="media__footer-title">' +
((__t = (titleTopic)) == null ? '' : __t) +
'</b>\n								';
 $.each(sectionItems[j].propSubject, function(key, value) { ;
__p += '\n									<span>' +
((__t = (value.value )) == null ? '' : __t) +
'</span>\n								';
 }); ;
__p += '\n								</div>\n							';
 } ;
__p += '\n							';
 if (sectionItems[j].hasOwnProperty("propType")) { ;
__p += '\n								<div class="media__footer-line">\n									<b class="media__footer-title">' +
((__t = (titleType)) == null ? '' : __t) +
'</b>\n									<span>' +
((__t = (type )) == null ? '' : __t) +
'</span>\n								</div>\n							';
 } ;
__p += '\n							</div>\n						';
 if (link !== undefined) { ;
__p += '\n							<div class="js-additional-fields" tyle="display: none">\n								<a id="lg-link" target="_blank" class="lg-link lg-icon" href="' +
((__t = (link )) == null ? '' : __t) +
'"></a>\n							</div>\n						';
 } ;
__p += '\n						';
 if (download !== undefined) { ;
__p += '\n							<div style="display: none">\n								<a id="lg-download" target="_blank" download class="lg-download lg-icon" href="' +
((__t = (download )) == null ? '' : __t) +
'"></a>\n							</div>\n						';
 } ;
__p += '\n						\'\n					';
 if (sectionItems[j].hasOwnProperty("ogTitle")) { ;
__p += ' \n						data-og-title = \'' +
((__t = (sectionItems[j].ogTitle )) == null ? '' : __t) +
'\'\n					';
 } ;
__p += '\n					';
 if (sectionItems[j].hasOwnProperty("ogURL")) { ;
__p += ' \n						data-og-url = \'' +
((__t = (sectionItems[j].ogURL )) == null ? '' : __t) +
'\'\n					';
 } ;
__p += '\n						class="media__zoom js-media__zoom" title="' +
((__t = (titleZoom )) == null ? '' : __t) +
'"></a>\n				</div>\n				';
 if (hrefHtml !== undefined) {;
__p += '\n					' +
((__t = (hrefHtml )) == null ? '' : __t) +
'\n				';
 } ;
__p += '\n			</figure>\n		';
 } ;
__p += '\n\n		</div>\n	';
 } ;
__p += '\n\n';
 } ;
__p += '\n';

}
return __p
}