// <div class="metals-block-expanded">
// 	<div class="metals-block-expanded__wrapper">
// 		<div class="metals-block-close"></div>
// 		<div class="metals-block-expanded__head">
// 			<img src="" alt="" class="metals-block-expanded__photo">
// 		</div>
// 		<div class="metals-block-expanded__content">
// 			<span class="metals-block__title"></span>
// 			<div class="metals-block__text"></div>
// 		</div>
// 	</div>
// </div>

const verge = require('verge');
var $metalsElement = $('.figure--metals');

function generateRange(pCount, pMin, pMax) { // generate random range
	let min = pMin < pMax ? pMin : pMax;
	let max = pMax > pMin ? pMax : pMin;
	let resultArr = [], randNumber;
	while (pCount > 0) {
		randNumber = Math.round(min + Math.random() * (max - min));
		if (resultArr.indexOf(randNumber) == -1) {
			resultArr.push(randNumber);
			pCount--;
		}
	}
	return resultArr;
}

if ($metalsElement.length > 0) {
	var $items = $metalsElement,
		$itemExp = $('.metals-block-expanded'),
		$close = $('.metals-block-close');

	function openMetalsBlock($object, $itemExp) {
		var $this = $object,
			$itemExp = $itemExp;
		if (!$this.hasClass('metals-block__item--exp')) {
			var img = $this.find('img').attr('data-src'),
				title = $this.find('.metals-block__item-title').html(),
				text = $this.find('.metals-block__item-text').html(),
				hashCode = $this.attr('data-id');
			$items.removeClass('metals-block__item--exp');
			$this.addClass('metals-block__item--exp');
			$itemExp
				.find('.metals-block-expanded__photo')
				.attr('src', img);
			$itemExp
				.find('.metals-block-expanded__title')
				.html(title);
			$itemExp
				.find('.metals-block-expanded__text')
				.html(text);
			$itemExp.velocity('transition.slideUpIn', {
				duration: 300
			});
			// window.location.hash = hashCode;

			if (verge.viewportW() > 768) {
				let $scrollObj;
				if ($('.js-products').length) {
					$scrollObj = $itemExp.closest('.js-products');
				} else {
					$scrollObj = $itemExp.closest('.metals-slide');
				}
				$('html, body').animate({
					scrollTop: $scrollObj.offset().top - 32 + 'px'
				}, 300);
			}
		}
	}

	// if (window.location.hash.length > 0) {
	// 	var hash = window.location.hash.substr(1);
	// 	if ($('[data-id=' + hash + ']').length > 0) {
	// 		openMetalsBlock('[data-id=' + hash + ']');
	// 	}
	// }

	$items.on('click', function (e) {
		e.preventDefault();
		let $itemExp;
		if ($('.js-products').length) {
			$itemExp = $(this).closest('.js-products').find('.metals-block-expanded');
		} else {
			$itemExp = $(this).closest('.metals-slide').find('.metals-block-expanded');
		}
		openMetalsBlock($(this), $itemExp);
	});

	$close.on('click', function (e) {
		e.preventDefault();
		let $container; 
		if ($('.js-products').length) {
			$container = $(this).closest('.js-products');
		} else {
			$container = $(this).closest('.metals-slide');
		}
		let $items = $container.find('.figure--metals'),
			$itemExp = $container.find('.metals-block-expanded');
		$itemExp.velocity('transition.slideDownOut', {
			duration: 300
		});
		$items.removeClass('metals-block__item--exp');
	});

	$(document).on('keydown', function (e) {
		if (e.keyCode == 27) {
			$itemExp.velocity('transition.slideDownOut', {
				duration: 300
			});
			$items.removeClass('metals-block__item--exp');
		}
	});

	$(document).on('click', function (e) {
		if ($('.metals-block-expanded').is(':visible')) {
			if (!$('.metals-block-expanded__wrapper').is(e.target) && $('.metals-block-expanded__wrapper').has(e.target).length === 0) {
				$itemExp.velocity('transition.slideDownOut', {
					duration: 300
				});
				$items.removeClass('metals-block__item--exp');
			}
		}
	});

	// random metals uses
	let $metalsSlide = $('.metals-slide');
	$metalsSlide.each(function () {
		let $this = $(this),
			$usesArr = $this.find('.js-metals-uses'),
			showUsesArr = generateRange(4, 0, $usesArr.length - 1);
		for (let i = 0; i < showUsesArr.length; i++) {
			$usesArr.eq(showUsesArr[i]).addClass('is-show');
		}
		$this
			.find('.js-metals-uses.is-show')
			.velocity('transition.fadeIn', {
				stagger: 250,
				duration: 500
			});
	});
}
