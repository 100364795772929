export let chartsTheme = {
	//
	color: [
		'#2B4559',
		'#005A9D',
		'#DEB65E',
		'#84A2CE',
		'#EBD3A1',
		'#B2B2B2',
		'#DADADA'
	],

	title: {
		textStyle: {
			fontWeight: 'normal'
		}
	},

	dataRange: {
		itemWidth: 10,
		color: ['#1790cf', '#a2d4e6']
	},

	toolbox: {
		color: ['#06467c', '#00613c', '#872d2f', '#c47630']
	},

	tooltip: {
		backgroundColor: 'rgba(243,244,248,0.6)',
		borderRadius: 0,
		borderWidth: 0,
		padding: 8,
		textStyle: {
			color: '#333'
		}
	},

	dataZoom: {
		dataBackgroundColor: '#dedede',
		fillerColor: 'rgba(154,217,247,0.2)',
		handleColor: '#005eaa'
	},

	grid: {
		borderWidth: 0
	},

	categoryAxis: {
		axisLine: {
			show: true,
			lineStyle: {
				color: '#ccc',
				width: 1,
				type: 'solid'
			}
		},
		axisLabel: {
			textStyle: {
				color: '#333'
			}
		},
		axisTick: {
			show: false
		}
	},

	valueAxis: {
		axisLine: {
			show: true,
			lineStyle: {
				color: '#ccc',
				width: 1,
				type: 'solid'
			}
		},
		axisTick: {
			show: false
		},
		axisLabel: {
			textStyle: {
				color: '#333'
			}
		}
	},

	timeline: {
		lineStyle: {
			color: '#005eaa'
		},
		controlStyle: {
			normal: {color: '#005eaa'},
			emphasis: {color: '#005eaa'}
		}
	},

	k: {
		itemStyle: {
			normal: {
				color: '#c12e34',
				color0: '#2b821d',
				lineStyle: {
					width: 1,
					color: '#c12e34',
					color0: '#2b821d'
				}
			}
		}
	},

	map: {
		itemStyle: {
			normal: {
				areaStyle: {
					color: '#ddd'
				},
				label: {
					textStyle: {
						color: '#c12e34'
					}
				}
			},
			emphasis: {
				areaStyle: {
					color: '#e6b600'
				},
				label: {
					textStyle: {
						color: '#c12e34'
					}
				}
			}
		}
	},

	force: {
		itemStyle: {
			normal: {
				linkStyle: {
					color: '#005eaa'
				}
			}
		}
	},

	chord: {
		itemStyle: {
			normal: {
				borderWidth: 1,
				borderColor: 'rgba(128, 128, 128, 0.5)',
				chordStyle: {
					lineStyle: {
						color: 'rgba(128, 128, 128, 0.5)'
					}
				}
			},
			emphasis: {
				borderWidth: 1,
				borderColor: 'rgba(128, 128, 128, 0.5)',
				chordStyle: {
					lineStyle: {
						color: 'rgba(128, 128, 128, 0.5)'
					}
				}
			}
		}
	},

	line: {
		itemStyle: {
			normal: {
				label: {
					show: true
				},
				lineStyle: {
					width: 8,
					type: 'solid',
					shadowColor: 'rgba(0,0,0,0)',
					shadowBlur: 5,
					shadowOffsetX: 3,
					shadowOffsetY: 3
				}
			},
			emphasis: {
				label: {
					show: false
				}
			}
		},
		symbolSize: 8,
		showAllSymbol: true
	},

	gauge: {
		axisLine: {
			show: true,
			lineStyle: {
				color: [[0.2, '#2b821d'], [0.8, '#005eaa'], [1, '#c12e34']],
				width: 5
			}
		},
		axisTick: {
			splitNumber: 10,
			length: 8,
			lineStyle: {
				color: 'auto'
			}
		},
		axisLabel: {
			textStyle: {
				color: 'auto'
			}
		},
		splitLine: {
			length: 12,
			lineStyle: {
				color: 'auto'
			}
		},
		pointer: {
			length: '90%',
			width: 3,
			color: 'auto'
		},
		title: {
			textStyle: {
				color: '#333'
			}
		},
		detail: {
			textStyle: {
				color: 'auto'
			}
		}
	},
	textStyle: {
		fontFamily: '"Proxima Nova W08", Tahoma, sans-serif'
	}
};
