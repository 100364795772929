const platform = require('platform');
const osName = platform.os.family;
const KEYCODE_LEFT = 37;
const KEYCODE_RIGHT = 39;
const verge = require('verge');

$(document).ready(() => {
	if (osName == 'OS X') {
		$('.page-nav .pagenav-help__key').html('Alt');
	} else if (osName == 'Android' || osName == 'iOS') {
		$('.pagenav-help').remove();
	} else {
		$('.page-nav .pagenav-help__key').html('Ctrl');
	}
});

$(document).keydown(function (event) {
	if (platform.os.family == 'OS X') {
		if (event.altKey && event.keyCode == KEYCODE_LEFT) {
			toLeft(event);
		} else if (event.altKey && event.keyCode == KEYCODE_RIGHT) {
			toRight(event);
		}
	} else {
		if (event.ctrlKey && event.keyCode == KEYCODE_LEFT) {
			toLeft(event);
		} else if (event.ctrlKey && event.keyCode == KEYCODE_RIGHT) {
			toRight(event);
		}
	}
});

function toLeft(event) {
	event.preventDefault(event);
	var lefllink = $('.page-nav__arrow--left'),
		lefllinkOut;
	if (lefllink.length > 1) {
		lefllink.each(function (i, el) {
			if (verge.inViewport($(el))) {
				lefllinkOut = $(el);
			}
		});
	} else {
		lefllinkOut = lefllink;
	}
	if (lefllinkOut.attr('href') != undefined) {
		document.location.href = lefllinkOut.attr('href');
	}
}

function toRight(event) {
	event.preventDefault(event);
	var rightlink = $('.page-nav__arrow--right'),
		rightlinkOut;
	if (rightlink.length > 1) {
		rightlink.each(function (i, el) {
			if (verge.inViewport($(el))) {
				rightlinkOut = $(el);
			}
		});
	} else {
		rightlinkOut = rightlink;
	}
	if (rightlinkOut.attr('href') != undefined) {
		document.location.href = rightlinkOut.attr('href');
	}
}
