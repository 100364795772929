const throttle = require('throttle-debounce/throttle');
const verge = require('verge');
const $timelineYears = $('#filter-timeline--years'),
	$preloader = $(`<div class="col-default-12 filter__preloader"><img src="/images/svg/loader.svg"></div>`);

let $filter = $('.js-filter'),
	$filterBtn = $('.filter__btn-filter'),
	ScrollMagic = require('scrollmagic'),
	$filterContainer = $('.filter-container'),
	$controls = $('.filter-controls');

global.checkAllActiveFilter = function ($container) {
	let arrLinksAll = $.makeArray($container.find('.filter-item__filter-link:not(.js-filter__all):not(.js-filter__clear)'));
	let arrLinksActive = $.makeArray($container.find('.filter-item__filter-link--active'));

	if (arrLinksAll.length == arrLinksActive.length) {
		$container.find('.js-filter__all').addClass('filter-item__filter-link--disabled');
	} else {
		$container.find('.js-filter__all').removeClass('filter-item__filter-link--disabled');
	}

	if (arrLinksActive.length) {
		$container.find('.js-filter__clear').removeClass('filter-item__filter-link--disabled');
	} else {
		$container.find('.js-filter__clear').addClass('filter-item__filter-link--disabled');
	}
};

function closeFilter() {
	$filter.removeClass('filter--open');
	$filterBtn.removeClass('filter__btn-filter--active');
}

function toggleFilter(e) {
	e.preventDefault();
	$filter.toggleClass('filter--open');
	$(this).toggleClass('filter__btn-filter--active');
}

$(document).on('click', '.filter__btn-filter', toggleFilter);
$(document).on('click', '.js-filter__close', closeFilter);
$(document).on('keyup', function (e) {
	if (e.keyCode === 27 && $filter.hasClass('filter--open')) {
		closeFilter();
	}
});
$(document).on('click', function (e) {
	if ($filter.hasClass('filter--open')) {
		if (!$filter.is(e.target) && $filter.has(e.target).length === 0 && !$filterBtn.is(e.target) && $filterBtn.has(e.target).length === 0) {
			closeFilter();
		}
	}
});

// FIXED SCROLL

const filterScroll = filterScroll || {};

if ($controls.length && !$controls.hasClass('filter-controls--static')) {
	filterScroll.controller = new ScrollMagic.Controller(),
	filterScroll.scene = new ScrollMagic.Scene({
		triggerElement: '.filter-container',
		duration: 0,
		triggerHook: 0,
		reverse: true
	})
	.setPin('.filter-controls', {
		pushFollowers: false
	});

	filterScroll.controller.addScene([
		filterScroll.scene
	]);
}

$(window).resize(
	throttle(300, function () {
		if ($controls.length && !$controls.hasClass('filter-controls--static')) {
			if (inWidth(768)) {
				if (filterScroll.controller.enabled()) {
					filterScroll.controller.enabled(false);
				}
			} else {
				if (!filterScroll.controller.enabled()) {
					filterScroll.controller.enabled(true);
				} else {
					if ($controls.css('position') == 'fixed') {
						$controls.css('left', $controls.closest('.scrollmagic-pin-spacer').offset().left);
					} else {
						$controls.css('left', 'auto');
					}
					filterScroll.controller.update(true);
				}
			}
		}
	})
);

// TIMELINE

$(document).on('click', '.js-timeline__more, .js-timeline__less', function (e) {
	e.preventDefault();
	$timelineYears.toggleClass('filter-timeline__list--open');
});

function toggleTimeline(el) {
	let $thisYear = $(el),
		$thisYearUL = $thisYear.siblings('.filter-timeline__list--2'),
		$openedYear = $timelineYears.find('.filter-timeline__link.filter-timeline__link--open'),
		$openedYearUL = $timelineYears.find('.filter-timeline__list--2.filter-timeline__list--open');

	function closeOpenedYear() {
		$openedYear
			.removeClass('filter-timeline__link--open');
		$openedYearUL
			.removeClass('filter-timeline__list--open');
	}

	if ($thisYear.hasClass('filter-timeline__link--open')) {
		closeOpenedYear();
	} else {
		closeOpenedYear();
		$thisYear
			.addClass('filter-timeline__link--open');
		$thisYearUL
			.addClass('filter-timeline__list--open');

		$thisYearUL
			.find('.js-timeline__link').eq(0)
			.trigger('click');
	}
}

$(document).on('click', '.js-timeline__link--years', function (e) {
	e.preventDefault();
	toggleTimeline($(this));
});
