const Datepicker = require('air-datepicker'),
	debounce = require('throttle-debounce/debounce'),
	moment = require('moment');
window.moment = moment;

const $futureEventsContainer = $('#future-events-container'),
	$pastEventsContainer = $('#past-events-container'),
	futureViewTable = require('./views/futureEventsTable.ejs'),
	pastViewTable = require('./views/pastEventsTable.ejs'),
	pastViewTableYear = require('./views/pastEventsTableYear.ejs'),
	pastViewTableMonth = require('./views/pastEventsTableMonth.ejs'),
	rememberView = require('./views/remember.ejs'),
	shareView = require('./views/share.ejs'),
	datepickerRenderEventsHTML = '<div class="events-calendar__info"></div>',
	emptyResult = localize({
		ru: 'Событий за выбранный временной диапазон с данными параметрами фильтра не найдено',
		en: 'No events for the selected time range of the data filter settings'
	});

$.fn.datepicker.language['en'] =  {
	days: ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
	daysShort: ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'],
	daysMin: ['Su','Mo','Tu','We','Th','Fr','Sa'],
	months: ['January','February','March','April','May','June','July','August','September','October','November','December'],
	monthsShort: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
	today: 'Today',
	clear: 'Clear',
	dateFormat: 'dd.mm.yyyy',
	timeFormat: 'hh:ii',
	firstDay: 1
};

function loadEvents(direction = 'new') {
	let params = eventsGetParams(),
		paramsStr= '';

	if (direction !== 'next' && params.hasOwnProperty('pageCurrent')) {
		params.pageCurrent = 1;
	}

	for (var param in params) {
		paramsStr += `${param}=${params[param]}&`
	}
	paramsStr = paramsStr.slice(0, -1);

	getJson(`${$('#calendar').attr('data-path')}?${paramsStr}`)
		.then(function (data) {
			// generate html tables
			let json = JSON.parse(data),
				futureEvents = json.future,
				pastEvents = json.past;

			if (futureEvents !== null && futureEvents !== undefined) {
				$futureEventsContainer
					.closest('.events')
					.show()
					.end()
					.html(futureViewTable(futureEvents));
			} else {
				$futureEventsContainer
					.closest('.events')
					.hide();
			}

			try {
				if (pastEvents !== null && pastEvents !== undefined) {
					if (direction == 'next') {
						let currentTableYear = $pastEventsContainer
								.find('table:last')
								.find('caption')
								.text(),
							currentTableMonth = $pastEventsContainer
								.find('table:last')
								.find('.events__tr--heading:last')
								.attr('data-number'),
							firstJsonYear = Object.keys(pastEvents)[0],
							firstJsonMonth = Object.keys(json.past[firstJsonYear])[0];

						if (firstJsonYear.slice(5) == currentTableYear && firstJsonMonth.slice(5) == currentTableMonth) {
							console.log('current year + current month');
							$pastEventsContainer
								.find('table:last')
								.find('tr:last')
								.after(pastViewTableMonth(pastEvents[firstJsonYear][firstJsonMonth]));
							delete pastEvents[firstJsonYear][firstJsonMonth];

							$pastEventsContainer
								.find('table:last')
								.find('tr:last')
								.after(pastViewTableYear(pastEvents[firstJsonYear]));
							delete pastEvents[firstJsonYear];

							$pastEventsContainer
								.find('.table:last')
								.after(pastViewTable(pastEvents));
						} else if (firstJsonYear.slice(5) == currentTableYear) {
							console.log('current year + other month');
							$pastEventsContainer
								.find('table:last')
								.find('tr:last')
								.after(pastViewTableYear(pastEvents[firstJsonYear]));
							delete pastEvents[firstJsonYear];

							$pastEventsContainer
								.find('.table:last')
								.after(pastViewTable(pastEvents));
						} else {
							console.log('other year');
							$pastEventsContainer
								.find('.table:last')
								.after(pastViewTable(pastEvents));
						}
					} else {
						$pastEventsContainer.html(pastViewTable(pastEvents));
					}
				} else {
					$pastEventsContainer
						.html(`<span class="events__notify">${emptyResult}</span>`);
					$pastEventsContainer
						.find('.js-events__load-more')
						.remove();
				}
			} catch(e) {
				console.log(e);
			}

			return json;
		})
		.then(function (json) {
			// btn load more
			try {
				if (json.pages.total !== null && json.pages.total !== undefined) {
					if (!($pastEventsContainer.find('.js-events__load-more').length)) {
						if (json.pages.current < json.pages.total) {
							$pastEventsContainer
								.append(`<div class="u-align-center events__load-more js-events__load-more" data-page-current="${json.pages.current}" data-page-total="${json.pages.total}">
								<img src="/images/svg/icon_scroll-bottom.svg">
							</div>`);
							$pastEventsContainer
								.find('.js-events__load-more')
								.velocity({
									opacity: 1,
									translateY: '+=10%'
								}, {
									loop: true,
									delay: 1000
								})
								.on('click', function () {
									loadEvents('next');
								});
						}
					} else {
						if (!(json.pages.current < json.pages.total)) {
							$pastEventsContainer
								.find('.js-events__load-more')
								.remove();
						} else {
							$pastEventsContainer
								.find('.js-events__load-more')
								.attr('data-page-current', json.pages.current)
								.attr('data-page-total', json.pages.total);
						}
					}
				} else {
					$pastEventsContainer
						.find('.js-events__load-more')
						.remove();
				}
			} catch(e) {
				console.log(e);
			}

			return json;
		})
		.then(function (json) {
			// init ux code
			try {
				if (json.future !== null && json.future !== undefined) {
					let futureEvents = [];
					for (var year in json.future) {
						for (var month in json.future[year]) {
							json.future[year][month].forEach(function (el) {
								futureEvents.push(el);
							})
						}
					}
					initEventsCode(futureEvents);
				}
			} catch(e) {
				console.log(e);
			}

			return json;
		})
		.then(function (json) {
			try {
				if (getHash().name.length) {
					let $tr = $(`tr[data-id="${getHash().name}"]`);
					$tr.addClass('events__tr--highlighted');
				}
			} catch(e) {
				console.log(e);
			}
			return json;
		})
		.catch(function (error) {
			console.error('Error: ' + error);
			showNotyfications(error, {type: 'error'});
		});
}

function initEventsCode(futureEvents) {
	/*
		Popups
	*/
	$.each($(document).find('.js-cal-remember'), function(i, el) {
		let $popup = $('<div class="events__popup js-events__popup"></div>');
		$popup
			.html(rememberView({
				"links": {
					"google": $(el).attr('data-google'),
					"yahoo": $(el).attr('data-yahoo'),
					"outlook": $(el).attr('data-outlook'),
					"ical": $(el).attr('data-ical')
				}
			}))
			.insertAfter($(el));

		$(el).on('click', function(e) {
			e.preventDefault();

			if ($popup.hasClass('events__popup--opened')) {
				$popup
					.removeClass('events__popup--opened')
					.hide()
					.siblings($('.events__icon'))
					.removeClass('events__icon--active');
			} else {
				$('.js-events__popup.events__popup--opened')
					.removeClass('events__popup--opened')
					.hide()
					.siblings($('.events__icon'))
					.removeClass('events__icon--active');

				$popup
					.addClass('events__popup--opened')
					.show()
					.siblings($('.events__icon'))
					.addClass('events__icon--active');
			}
		});
	});

	$.each($(document).find('.js-cal-share'), function(i, el) {
		let $popup = $('<div class="events__popup js-events__popup"></div>');
		$popup
			.html(shareView({
				"links": {
					"fb": $(el).attr('data-fb'),
					"tw": $(el).attr('data-tw'),
					"vk": $(el).attr('data-vk')
				}
			}))
			.insertAfter($(el));

		$(el).on('click', function(e) {
			e.preventDefault();

			if ($popup.hasClass('events__popup--opened')) {
				$popup
					.removeClass('events__popup--opened')
					.hide()
					.siblings($('.events__icon'))
					.removeClass('events__icon--active');
			} else {
				$('.js-events__popup.events__popup--opened')
					.removeClass('events__popup--opened')
					.hide()
					.siblings($('.events__icon'))
					.removeClass('events__icon--active');

				$popup
					.addClass('events__popup--opened')
					.show()
					.siblings($('.events__icon'))
					.addClass('events__icon--active');
			}
		});
	});

	// Get min date for popup calendar
	let minDateFromJson = parseInt(futureEvents[Object.keys(futureEvents)[0]].date.split('-')[0]),
		currentStartDay = moment().startOf('day').unix(),
		minDate;

	if (minDateFromJson > currentStartDay) {
		minDate = moment.unix(currentStartDay).toDate();
	} else {
		minDate = moment.unix(minDateFromJson).toDate();
	}

	let $eventsDatepicker = $('.js-events__toggle-calendar')
		.datepicker({
			language: getLang(),
			classes: 'events__calendar',
			minDate: minDate,
			onRenderCell: function (date, cellType) {
				try {
					let unixStartDate = moment(date).startOf('day').unix(),
						unixEndDate = moment(date).endOf('day').unix();

					// if (cellType == 'day' && eventDates.indexOf(currentDate) != -1) {
					if (cellType == 'day') {
						let findedDay = futureEvents.filter(function(el, i) {
							var arDates = el.date.split('-');
							if (arDates.length > 1) {
								return (unixStartDate >= moment.unix(parseInt(arDates[0])).startOf('day').unix()) && (unixEndDate <= moment.unix(parseInt(arDates[1])).endOf('day').unix());
							} else {
								return (el.date >= unixStartDate) && (el.date <= unixEndDate);
							}
						});
						if (findedDay.length) {
							findedDay = findedDay.filter((event, i, self) =>
								i === self.findIndex((d) => (
									d.id === event.id
								))
							)
							let $html = $('<div></div>');
							findedDay.forEach((el) => {
								$html.append(`<span data-event-id="${el.id}" class="dp-notes__dot dp-notes__dot--${el.type}"></span>`)
							});
							return {
								html: `${date.getDate()} <div class="dp-notes">${$html.html()}</div>`
							}
						}
					}
				} catch(e) {
					console.log(e);
				}
			},
			onSelect: function (fd, date, inst) {
				try {
					let unixStartDate = moment(date).startOf('day').unix(),
						unixEndDate = moment(date).endOf('day').unix(),
						html = '';

						if (date) {
							let findedDay = futureEvents.filter(function(el, i) {
								var arDates = el.date.split('-');
								if (arDates.length > 1) {
									return (unixStartDate >= moment.unix(parseInt(arDates[0])).startOf('day').unix()) && (unixEndDate <= moment.unix(parseInt(arDates[1])).endOf('day').unix());
								} else {
									return (el.date >= unixStartDate) && (el.date <= unixEndDate);
								}
							});
							if (findedDay.length) {
								findedDay = findedDay.filter((event, i, self) =>
									i === self.findIndex((d) => (
										d.id === event.id
									))
								)
								let $html = $('<div></div>');
								findedDay.forEach((el) => {
									$html.append(`<span data-event-id="${el.id}" class="events-calendar__info-span events-calendar__info-span--${el.type}"><a href="#" class="js-events-calendar__info-link">${el.name}</a></span>`)
								});
								html = $html.html();
								$('#datepickers-container')
									.find('.events-calendar__info')
									.show();
							}
						}

					if (!$('#datepickers-container').find('.events-calendar__info').length) {
						$(datepickerRenderEventsHTML)
							.appendTo($('#datepickers-container').find('.datepicker'));
					}
					$('#datepickers-container')
						.find('.events-calendar__info')
						.html(html);
				} catch(e) {
					console.log(e);
				}

			},
			onChangeView: function (view) {
				try {
					if (view == 'days' && $('.events-calendar__info').find('span').length) {
						$('#datepickers-container')
							.find('.events-calendar__info')
							.show();
					} else {
						$('#datepickers-container')
							.find('.events-calendar__info')
							.hide();
					}
				} catch(e) {
					console.log(e);
				}

			},
			onShow: function (inst, animationCompleted) {
				try {
					if (!animationCompleted && !$('#datepickers-container').find('.events-calendar__info').length) {
						$(datepickerRenderEventsHTML)
							.appendTo($('#datepickers-container').find('.datepicker'));
					}

					$('#datepickers-container')
						.find('.events-calendar__info')
						.show();
				} catch(e) {
					console.log(e);
				}

			},
			onHide: function (inst, animationCompleted) {
				try {
					if (animationCompleted) {
						$('#datepickers-container')
							.find('.events-calendar__info')
							.hide();
					}
				} catch(e) {
					console.log(e);
				}

			}
		});

		$('.js-events__toggle-calendar').data('datepicker').selectDate(new Date);

	/*
		Overlay
	*/
	$(document).on('click', function (e) {
		if ($(document).find('.js-events__popup.events__popup--opened').length) {
			if (!$(document).find('.js-cal-remember').is(e.target) && $(document).find('.js-cal-remember').has(e.target).length === 0 && !$(document).find('.js-cal-share').is(e.target) && $(document).find('.js-cal-share').has(e.target).length === 0 && !$(document).find('.js-events__popup').is(e.target) && $(document).find('.js-events__popup').has(e.target).length === 0) {
					$(document).find('.js-events__popup.events__popup--opened')
						.removeClass('events__popup--opened')
						.hide()
						.siblings($('.events__icon'))
						.removeClass('events__icon--active');
			}
		}

		$(document)
			.find('.events__tr--highlighted')
			.removeClass('events__tr--highlighted');

		if ($('.js-events-calendar__info-link').is(e.target)) {
			e.preventDefault();
			let $tr = $(`tr[data-id="${$(e.target).parent().attr('data-event-id')}"]`);

			$('.js-events__toggle-calendar').data('datepicker').hide();
			$tr.addClass('events__tr--highlighted');
		}
	});
}

function eventsGetParams() {
	let params = {},
		$arCheckedType = $('.checkbox--cal').find('input:checked'),
		$arYears = $('#calendar')
								.find('[data-filter-container="years"]')
								.find('.js-filter__link.filter-item__filter-link--active'),
		$arMonths = $('#calendar')
								.find('[data-filter-container="months"]')
								.find('.js-filter__link.filter-item__filter-link--active');

	if ($('.js-events__load-more').length) {
		params.pageCurrent = parseInt($('.js-events__load-more').attr('data-page-current')) + 1;
	}
	if ($arCheckedType.length) {
		params.arTypes = [];
		$arCheckedType.each(function(i, el) {
			params.arTypes.push(el.name);
		})
	}
	if ($arYears.length) {
		params.arYears = [];
		$arYears.each(function(i, el) {
			params.arYears.push($(el).data('filter-id'));
		})
	}
	if ($arMonths.length) {
		params.arMonths = [];
		$arMonths.each(function(i, el) {
			params.arMonths.push($(el).data('filter-id'));
		})
	}
	return params;
}

if ($('#calendar').length) {
	loadEvents();

	$('#calendar').on('click', '.js-filter__link', function (e) {
		let $this = $(this),
			$container = $this.closest('.filter__group');

		if ($this.hasClass('filter-item__filter-link--disabled')) {
			return false;
		}

		if ($this.hasClass('js-filter__all')) {
			$container
				.find('.filter-item__filter-link:not(.js-filter__all):not(.js-filter__clear)')
				.addClass('filter-item__filter-link--active');
			$this.addClass('filter-item__filter-link--disabled');
			$container
				.find('.js-filter__clear')
				.removeClass('filter-item__filter-link--disabled');
		} else if ($this.hasClass('js-filter__clear')) {
			$container
				.find('.filter-item__filter-link:not(.js-filter__all):not(.js-filter__clear)')
				.removeClass('filter-item__filter-link--active');
			$this.addClass('filter-item__filter-link--disabled');
			$container
				.find('.js-filter__all')
				.removeClass('filter-item__filter-link--disabled');
		} else {
			$(this).toggleClass('filter-item__filter-link--active');
			checkAllActiveFilter($container);
		}
	});

	$('.checkbox--cal')
		.find('input')
		.on('change', debounce(500, loadEvents));

	$('#calendar')
		.find('.js-filter__link')
		.on('click', debounce(500, loadEvents));

	// $(document)
	// 	.find('.js-events__load-more')
	// 	.on('click', function () {
	// 		loadEvents('next');
	// 	});
}
